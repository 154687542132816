// Customizable Area Start

import React from "react";
import { eyeIcon, likeIcon, featureIcon, closeIcon } from "../assets";
import MultiSelect from "../../../../components/src/MultiSelect.web";
import SingleSelect from "../../../../components/src/SingleSelect.web";
import ToastMSG from "../../../../components/src/ToastMSG.web";
import ProjectDetails from "./ProjectDeatils.web";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const customStyle = require('./customStyle.js');

const CustomBox = withStyles((theme) => ({
    root: {
        '&.discover-project-container': {
            backgroundColor: "#111111",
            padding: "0 48px",
            marginTop: "92px",
            paddingBottom: "50px"
        },
        '&.discover-project-container .card-container': {
            maxWidth: "94%",
            margin: "20 auto 0 auto",
            
        },
        '&.discover-project-container .select-btn': {
            backgroundColor: "transparent",
            fontFamily: "Inter-SemiBold, sans-serif",
            fontSize: "14px",
            lineHeight: "16.94px",
            display: "flex",
            alignItems: "center",
            color: "#fff2e3",
            justifyContent: "center"
        },
        '&.discover-project-container .single-select-container .select-btn': {
            border: "none",
            padding: "0",
            justifyContent: "flex-start"
        },
        '&.discover-project-container .manage-gap': {
            columnGap: "19px"
        },
        '&.discover-project-container .card-style': {
            maxWidth: "100%",
            height: "12rem",
            borderRadius: "10px",
            marginBottom: "8px"
        },
        '&.discover-project-container .project-title': {
            paddingRight: "10px"
        },
        '&.discover-project-container .d-none': {
            display: "none"
        },
        '&.discover-project-container .card-style img': {
            width: "100%",
            height: "100%",
            borderRadius: "10px"
        },
        '&.discover-project-container .options-position': {
            marginRight: "19px",
            marginTop: "33px",
            cursor: "pointer"
        },
        '&.discover-project-container .title-container': {
            width: "378.33px !important"
        },
        '&.discover-project-container .view-more-btn': {
            width: "145px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "55px"
        },
        '&.discover-project-container .view-more-btn:active': {
            backgroundColor: "#252525"
        },
        '&.discover-project-container .select-container,&.discover-project-container .tags-container': {
            display: "flex",
            alignItems: "center",
            columnGap: "24px",
            marginTop: "40px"
        },
        '&.discover-project-container .tags-container': {
            flexWrap: "nowrap",
            overflow: "auto",
            scrollbarWidth: "none",
            MsOverflowStyle: "none"
        },
        "& .no_projects_container":{
            display:'flex',width:"100%", justifyContent:"center", alignItems:'center'
        }, "& .no_project_text":{
            color:"#fff2e3", fontSize:25, fontWeight:500
        },
        '&.discover-project-container .heading-text': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "36px",
            lineHeight: "43.57px",
            color: "#fff2e3"
        },
        [theme.breakpoints.between(1370, 1460)]: {
            '&.discover-project-container .project-title': {
                maxWidth: "114px",
                paddingRight: "6px"
            },
            '&.discover-project-container .manage-gap': {
                columnGap: "10px"
            },
            '&.discover-project-container .title-container': {
                width: "288px !important"
            },
            '&.discover-project-container .card-text': {
                fontSize: "10px"
            }
        },
        ...customStyle.customStyle(theme)
    }
}))(Box);

const CustomButton = withStyles({
    root: {
        textTransform: "none",
        '&.button-elm': {
            height: "44px",
            borderRadius: "7px",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
            fontFamily: "Inter-Medium,sans-serif",
            lineHeight: "24px",
            letterSpacing: "0.38px",
            fontSize: "16px",
            display: "flex",
            border: "none",
            gap: "10px",
            backgroundColor: "transparent",
            width: "99px",
            marginTop: "40px"
        },
        '&.button-elm.secondary-btn': {
            backgroundColor: "#fff2e3"
        },
        '&.button-elm.default-btn': {
            color: "#fff2e3",
            backgroundColor: "#0d0d0d"
        },
        '&.button-elm.disabled-btn': {
            backgroundColor: "#7d7d7d"
        },
        '&.button-elm.disabled-btn, &.button-elm.secondary-btn': {
            color: "#121212"
        }
    }
})(Button);

import DiscoverProjectController, { Props } from "./DiscoverProjectController";

class DiscoverProject extends DiscoverProjectController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <CustomBox className="discover-project-container" onClick={() => this.handleCloseData()}>
                <Typography className="heading-text">Discover Projects</Typography>
                <CustomBox className="tags-container">
                    {this.state.allTags.map(item => <>
                        <CustomBox data-test-id="tags-data" onClick={() => this.tagsSelectionChange(item)} key={`tags${item}`} className={`tags ${this.handleSelected(item) ? "tags-active" : ""}`}>
                            {this.handleSelected(item) ? <img src={closeIcon} alt="close icon" /> : null}
                            {item}
                        </CustomBox>
                    </>)}
                </CustomBox>
                <CustomBox className="select-container ">
                    <MultiSelect toggleState={this.toggleMulti} singleColumn={false} open={this.state.openMulti} title="Creative fields" data={this.state.allCategory} selected={this.state.selected} onChange={this.handleChange} />
                    <SingleSelect toggleState={this.toggleSingle} open={this.state.openSingle} title="Sort" data={["Trending", "Recently listed", "Recommended"]} selected={this.state.selectedElm} onChange={this.handleSelectChange} />
                </CustomBox>
                <Grid container className="main_grid_container" spacing={4} >
                    {
                        this.state.projectList.length ? this.state.projectList.map((item) => (
                            <Grid  item  xs={3}  data-test-id="grid-items" key={item.id} onClick={() => this.openModal(item)}>
                                <Box className="card_container">
                                <Box>
                                    <img className="card_image" src={`${item.cover_image_url}`} alt="project cover image" />
                                </Box>
                                <Box className="title_container">
                                <CustomBox className="project-title">
                                        <Typography className="card-label" title={item.project_name}>{item.project_name}</Typography>
                                        <Typography className="card-text">{item.username}</Typography>
                                    </CustomBox>
                                    <CustomBox className="manage-gap" style={{ display: "flex", height: "24px" }}>
                                        <CustomBox className="manage-space">
                                            <span className="card-text">{item.views}</span>
                                            <img className="eyeIcon" src={eyeIcon} alt="eye icon" />
                                        </CustomBox>
                                        <CustomBox className="manage-space">
                                            <span className="card-text">{item.likes}</span>
                                            <img className="likeIcon" src={likeIcon} alt="like icon" />
                                        </CustomBox>
                                        <CustomBox className="manage-space-feature">
                                            <span className="card-text">{item.saved_count}</span>
                                            <img className="featureIcon" src={featureIcon} alt="feature icon" />
                                        </CustomBox>
                                    </CustomBox>
                                </Box>
                                </Box>
                            </Grid>
                        )) : <Box className="no_projects_container">
                            <Typography id="no_projects_to_show" className="no_project_text">No Projects To Show</Typography>
                        </Box>
                    }
                </Grid>
                <CustomButton onClick={() => this.handleMoreProject()} className={`button-elm default-btn view-more-btn ${this.showMore() ? '' : 'd-none'}`}>View more</CustomButton>
                <ToastMSG open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
                <Box data-test-id={"projectDetailsModal"}>
                {this.state.showDetails && <ProjectDetails token={this.state.token} selectedProject={this.state.selectedProject} open={this.state.showDetails} closeModal={this.closeModal} allprojectList={this.state.specificProjectList} />}
                </Box>
            </CustomBox>
        );
    }
}

export default DiscoverProject;

// Customizable Area End




