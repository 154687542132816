// Customizable Area Start
import React from "react";
import ProposalsController from "./Proposals.Controller";
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Box, Button, IconButton, InputAdornment, Tab, Tabs, TextField, Typography, styled } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import StarIcon from '@material-ui/icons/Star';

export class Proposals extends ProposalsController {




    renderSection = () => {
        return (
            <CustomTextField
                variant="outlined"
                size="small"
                id="find_work_search"
                data-test-id="searchfeild"
                fullWidth
                placeholder="Type here"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIconStyled />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {<IconButton data-test-id="searchclear"
                                onClick={this.clearText} id="clear-text" style={{ color: "white", marginRight: 20, fontFamily: "Inter , sans-serif", }} size="small">
                                x
                            </IconButton>}
                            <SearchButton>

                                Search
                            </SearchButton>
                        </InputAdornment>
                    ),

                }}
            />
        )
    }

    render() {
        return (
            <>
                <Box>
                    <Typography
                        style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "19.26px",
                            textAlign: "left",
                            color: "#FFF2E3",
                            textDecoration: "underline"
                        }}>View work details</Typography>
                </Box>

                <Box
                    style={{
                        marginTop: "45px"
                    }}>
                    <Typography
                        style={{
                            fontFamily: "Inter",
                            fontSize: "36px",
                            fontWeight: 700,
                            lineHeight: "43.57px",
                            textAlign: "left",
                            color: "#FFF2E3",
                        }}>Proposals</Typography>
                </Box>

                <Box
                    style={{
                        borderBottom: "1px solid grey",
                        marginTop: "58px",
                        width: "45%",
                    }}>
                    <Tabs
                        value={this.state.selectedEventTab}
                        onChange={this.handleEventTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Pending" {...this.a11yProps(0)} style={{ textTransform: 'capitalize', color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600 }} />
                        <Tab label="Shortlisted" {...this.a11yProps(1)} style={{ textTransform: 'capitalize', fontFamily: "Inter", fontWeight: 400, color: "#FFF2E3" }} />
                        <Tab label="Archived" {...this.a11yProps(2)} style={{ textTransform: 'capitalize', fontFamily: "Inter", fontWeight: 400, color: "#FFF2E3" }} />

                    </Tabs>
                </Box>

                <MainBox>
                    <SearchBox>
                        {this.renderSection()}
                    </SearchBox>
                    <FiltersBox>
                        <RateButton>
                            <Typography style={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "16.94px",
                                color: "#FFF2E3",
                                textTransform: "capitalize"
                            }}>
                                Ratings
                            </Typography>
                            <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />

                        </RateButton>
                        <Box>
                            <LocationButton
                                onClick={() => { this.setState({ openLocationDropDown: !this.state.openLocationDropDown }) }}
                            >
                                <Typography style={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "16.94px",
                                    color: "#FFF2E3",
                                    textTransform: "capitalize"
                                }} >
                                    Location
                                </Typography>

                                <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />

                            </LocationButton>

                        </Box>

                        <Box style={{ position: "relative" }}>
                            <SortButton
                                onClick={() => { this.setState({ openSortDropDown: !this.state.openSortDropDown }) }}>
                                <Typography style={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "16.94px",
                                    color: "#FFF2E3",
                                    textTransform: "capitalize",
                                }}  >
                                    Sort
                                </Typography>

                                <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />

                            </SortButton>

                        </Box>

                    </FiltersBox>
                </MainBox >

                <Box style={{ padding: "3rem 0rem", display: "flex", justifyContent: "space-between" }}
                    onClick={() => { this.setState({ openSortDropDown: false }) }}
                >
                    {
                        this.state.pendingDataList?.map((element: any) => {
                            return (
                                <CardBox style={{ height: "533px", width: "398px", border: "1.15px solid #6D6D6D", borderRadius: "11.54px" }}>
                                    <Box style={{ display: "flex", alignItems: "center", gap: "30px", padding: "2rem 1rem", flexWrap: "wrap" }}>
                                        <Box style={{ width: "100px", height: "100px", border: "1px solid white", borderRadius: "50%" }}>
                                            {element.attributes.profile_photo}
                                        </Box>
                                        <Box><Typography style={{
                                            fontFamily: "Inter",
                                            fontSize: "20px",
                                            fontWeight: 700,
                                            lineHeight: "24.2px",
                                            color: "#FFF2E3",
                                            textTransform: "capitalize"
                                        }}>name</Typography>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                lineHeight: "19.36px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                paddingTop: "0.5rem"
                                            }}>{element.attributes.location}</Typography></Box>
                                    </Box>
                                    <Box style={{ display: "flex", flexWrap: "wrap", height: "51.91px", justifyContent: "space-around", padding: "0px 0.5rem" }}>
                                        <Box>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "16.94px",
                                                color: "#7D7D7D",
                                                textTransform: "capitalize",
                                                textAlign: "center"
                                            }}>Views</Typography>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                lineHeight: "19.36px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                paddingTop: "0.5rem",
                                                textAlign: "center"
                                            }}>{element.attributes.view_count}</Typography>
                                        </Box>
                                        <Box style={{ borderLeft: "1px solid grey", paddingLeft: "1rem" }}>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "16.94px",
                                                color: "#7D7D7D",
                                                textTransform: "capitalize",
                                                textAlign: "center"
                                            }}>Likes</Typography>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                lineHeight: "19.36px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                paddingTop: "0.5rem",
                                                textAlign: "center"
                                            }}>{element.attributes.likes_count}</Typography>
                                        </Box>
                                        <Box style={{ borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px 1.5rem" }}>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "16.94px",
                                                color: "#7D7D7D",
                                                textTransform: "capitalize",
                                                textAlign: "center"
                                            }}>Submitted rate</Typography>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                lineHeight: "19.36px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                paddingTop: "0.5rem",
                                                textAlign: "center"
                                            }}>{element.attributes.service_rate} pr/hr</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "16.94px",
                                                color: "#7D7D7D",
                                                textTransform: "capitalize",
                                                textAlign: "center"
                                            }}>Ratings</Typography>
                                            <Box style={{
                                                display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px",
                                                height: "26px", width: "60px", background: "green", borderRadius: "3px", gap: "2px"
                                            }}>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: 500,
                                                    lineHeight: "19.36px",
                                                    color: "#FFF2E3",
                                                    textTransform: "capitalize",
                                                    textAlign: "center",
                                                }}>{element.attributes.rating}
                                                </Typography>
                                                <Box><StarIcon style={{ color: "white", width: "14px", height: "13px" }} /></Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box style={{ borderTop: "0.97px solid #545454", marginTop: "20px" }}>
                                        <Box style={{ padding: "1rem" }}>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "11px",
                                                fontWeight: 400,
                                                lineHeight: "13.31px",
                                                color: "#66625C",
                                                textTransform: "capitalize",
                                                textAlign: "left",
                                            }}>{element.attributes.received_time_ago}</Typography>
                                        </Box>
                                        <Box style={{
                                            padding: "1rem"
                                        }}>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                                lineHeight: "16.94px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                textAlign: "left",
                                            }}>Pitch</Typography>
                                        </Box>
                                        <Box style={{ padding: "1rem" ,height:"15%"}}>
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "16.94px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                textAlign: "left",
                                            }}>{element.attributes.give_your_pitch}</Typography>
                                        </Box>

                                        <Button style={{ padding: "1rem", display: "flex", alignItems: "center", gap: "5px", cursor: "pointer",
                                        justifyContent:"space-between",width:"130px" }}>
                                            <CheckCircleOutlineIcon style={{ color: "#8C8C8C", height: "27px", width: "27px" }} />
                                            <Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                lineHeight: "24px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                letterSpacing: "0.379999995231628px",
                                                textDecoration: "underline"
                                            }}>Shortlist</Typography>
                                        </Button>
                                    </Box>

                                </CardBox>
                            )
                        })
                    }


                </Box>

            </>
        )
    }
}

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#1c1c1c',
    fontSize: 14,
    fontFamily: "Inter , sans-serif",
    width: "100%",
    borderRadius: 20,
    height:"48px",
    display: "flex",
  alignItems: "center",
  justifyContent: "center",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiInputBase-input': {
        color: '#fff',
        fontSize: 14,
        '&:placeholder': {
            textDecoration: 'italic',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#66625C',
    },
    [theme.breakpoints.down("md")]: {
    }
})


)


const SearchButton = styled(IconButton)({
    color: '#fff',
    textTransform: 'none',
    fontSize: 14,
    textDecoration: 'underline',
    fontFamily: "Inter , sans-serif",
    borderLeft: '1px solid #434343',
    borderRadius: '0px',
    '&:disabled': {
        color: '#888',
        textDecoration: 'none',
        opacity: 0.5,
    },
});

const SearchIconStyled = styled(SearchIcon)({
    color: '#66625C',
    fontSize: 18
});

const SearchBox = styled(Box)(({ theme }) => ({
    color: '#66625C',
    fontSize: 18,
    width: "70%",
    height:"48px",
    "@media (max-width:960px)": {
        width: "90%",
    },
    "@media (min-width:720px)": {

    },
})
);


const MainBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "50px",
    flexWrap: "wrap",
    width: "100%",
    "@media (max-width:960px)": {
        height: "100px",
        width: "100%",
    },
    "@media (min-width:720px)": {

    },

});

const FiltersBox = styled(Box)({
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    "@media (max-width:960px)": {
        gap: "5rem",

    },
    "@media (min-width:720px)": {

    },

});

const RateButton = styled(Button)({
    width: "99px",
    height: "37px",
    gap: "9px",
    borderRadius: "6px",
    opacity: "0px",
    border: "1px solid #66625C"
});
const LocationButton = styled(Button)({
    height: "37px",
    gap: "9px",
    borderRadius: "6px",
    opacity: "0px",
    border: "1px solid #66625C"
});
const SortButton = styled(Button)({
    height: "37px",
    gap: "9px",
    borderRadius: "6px",
    opacity: "0px",
});

const CardBox = styled(Box)({
    height: "533px",
    width: "398px",
    border: "1.15px solid #6D6D6D",
    borderRadius: "11.54px",

    "@media (max-width:960px)": {
        height: "533px",
        width: "200px",
    },
    "@media (min-width:720px)": {

    },
});

// Customizable Area End

