// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Box } from "@material-ui/core";
import { Button } from "@mui/material";

const dropIcon = require('../../blocks/assets/solidDropIcon.svg');

export interface Props {
  selected: string | number,
  onChange: Function,
  data: (string | number)[],
  title: string,
  open: boolean,
  toggleState: Function
}

interface S {
  selectedData: string | number
}

interface SS {
  id: string | number;
}

const customStyle = {
    paperStyle: {
        style: {
            maxHeight: 300,
            width: 179,
            backgroundColor: "#111111E5",
            borderRadius: "14.15px",
            border: "0.71px solid #434343",
            padding: '0',
            boxShadow: "none",
            cursor: "pointer"
        }
    },
    mewnuItem: {
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        lineHeight: "16.94px",
        color: "#fff",
        height: "48px",
        padding: "0 14px",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
    },
    selectedMewnuItem: {
        backgroundColor: "#2d2d2d"
    },
    firstElm : {
        borderRadius: "14.15px 14.15px 0 0"
    },
    lastElm : {
        borderRadius: "0 0 14.15px 14.15px"
    }
}

const styles = {
    container: {
      position: 'relative',
      '& .MuiInput-underline::before': {
        border: 'none !important',
        display: 'none',
      },
      '& #simple-select-elm': {
        borderColor: 'transparent !important',
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .hide-btn-elm': {
        visibility: 'hidden',
      },
      '& .hide-data': {
        display: 'none',
      },
      '& .show-data': {
        display: 'block',
      },
    },
    optionContainer: {
        position: "absolute", 
        top: "40px",
    },
    valueElm : {
        width: "max-content",
         color: '#fff2e3', 
         fontFamily: "Inter, sans-serif", 
         fontSize: "14px", 
         lineHeight: "16.94px",
        cursor: "pointer"
    },
    toggleButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    selectBtn: {
      backgroundColor: 'transparent',
      fontFamily: 'Inter-SemiBold, sans-serif',
      fontSize: '14px',
      lineHeight: '16.94px',
      display: 'flex',
      alignItems: 'center',
      color: '#fff2e3',
      padding: 0,
      border: 'none',
      columnGap: '8px',
      justifyContent: 'center',
      textTransform: 'none',
      transition: 'background-color 0.5s ease', 
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
  
  
export default class SingleSelect extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedData: ''
        };
    }

    async componentDidMount() {
        this.setState({selectedData: this.props.selected || this.props.data[0]});
      }
    
      handleChange(rowData: string | number, event?:React.MouseEvent<HTMLElement, MouseEvent>) {
        this.setState({ selectedData: rowData });
        this.props.onChange(rowData);
        this.props.toggleState(event);
      }
    
      toggleMenu(event?:React.MouseEvent<HTMLElement, MouseEvent>) {
        this.props.toggleState(event);
      }

    render() {
        return (
            <Box className="single-select-container" sx={styles.container}>
                <Box data-test-id="toggleButton" onClick={(event) => this.toggleMenu(event)}>
                    <Button data-test-id="toggleButtonFirst" disableRipple={true} sx={{ ...styles.selectBtn }} className="select-btn"><span className="title-elm">{this.props.title}</span> <img src={dropIcon} alt="dropdown icon" /> </Button>
                    <Box className="value-elm" sx={{ ...styles.valueElm }} >{this.state.selectedData}</Box>
                </Box>
                <Box sx={{ ...styles.optionContainer }} className={`option-container ${this.props.open ? 'show-data' : "hide-data"}`}>
                    <Box style={{ zIndex: 12, ...customStyle.paperStyle.style }}>
                        {this.props.data.map((list, index) => <Box
                            key={list}
                            className="menuItem"
                            data-test-id="menuItem"
                            onClick={(event) => this.handleChange(list, event)}
                            style={{ ...customStyle.mewnuItem, ...(index === 0 ? customStyle.firstElm : (index === (this.props.data.length - 1) ? customStyle.lastElm : {})), ...(list === this.state.selectedData ? customStyle.selectedMewnuItem : {}) }}
                        >{list}</Box>)}
                    </Box>
                </Box>
            </Box>
        );
    }
}
// Customizable Area End