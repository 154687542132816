// Customizable Area Start
import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName, } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getToken } from "../../../components/src/Base64Conversion";
export const configJSON = require("./config");


export interface Props {
    cardId: string;

}
interface S {
    isSearched: boolean;
    findWorkSearch: string;
    selectedEventTab: number;
    loading: boolean;
    token: string;
    pendingDataList: string[];
    openSortDropDown: boolean,
    SelectedDropDownLocationName: string
    openLocationDropDown: boolean;
    SelectedDropDownName: string




}
interface SS {
    id: any;
}
export default class ProposalsController extends BlockComponent<
    Props,
    S,
    SS
> {

    pendingListDataCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            isSearched: false,
            findWorkSearch: '',
            selectedEventTab: 0,
            loading: false,
            token: '',
            pendingDataList: [],
            openSortDropDown: false,
            SelectedDropDownName: "",
            openLocationDropDown: false,
            SelectedDropDownLocationName: "",



        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.hanleApiResposne(apiRequestCallId, responseJson)
        }
    }

    getTokenData = async () => {
        const token = await getToken();
        this.setState({ token: token })
    };

    async componentDidMount() {
        await this.getTokenData();
    }

    hanleApiResposne = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.pendingListDataCallId && responseJson.data) {
            this.setState({
                pendingDataList: responseJson.data
            });
        }
    }

    clearText = () => {
        this.setState({ findWorkSearch: '', isSearched: false })
    }

    a11yProps(index: any) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    handleEventTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ selectedEventTab: newValue });
    };

    pendingList = (cardId: any) => {
        this.setState({ loading: true });
        let token = this.state.token;

        const header = {
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.pendingListDataCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.pendingDataEndPoint}?work_opportunity_id=${cardId}&page=${1}&per_page=${20}&proposal_status=${"pending"}`

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {


        if (this.props.cardId !== prevProps.cardId) {
            if (this.props.cardId) {
                this.pendingList(this.props.cardId)
            }
        }
    }
    // Customizable Area End
}