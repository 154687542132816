// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { getToken } from "../../../../components/src/Base64Conversion";
import axios from "axios";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { setStorageData } from "../../../../framework/src/Utilities"
export const configJSON = require("../config");
let tokenData = "";
export interface Props {
  navigation: any;
  id: string;
}



interface Response {
  work_opportunity_id: number | string,
  account_id: number | string,
  title: string,
  rate_type:string,
  hourly_rate: string | number,
  project_timeline: string | number,
  project_timeline_type?: string,
  description: string,
  name: string,
  location: string,   
  posted_time_ago: string
}

interface S {
  message: string,
  page: number,
  open: boolean,
  openOppertunity:boolean,
  action: string,
  selectedOpportunityId:number | string,
  opportunityList: Response[]
}

interface SS {
  id: any;
}

export default class WorkOpportunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),     
      getName(MessageEnum.SessionSaveMessage),      
     
    ];
    this.state = {
      page: 1,
      message: "",
      open: false,
      openOppertunity:false,
      selectedOpportunityId:0,
      action: "",
      opportunityList: []
    };
  }

  formatToLocaleString = (data: Response) => {
  const  rate_types  = data.rate_type;
    if (rate_types && rate_types.includes( "hourly")) {
      return "pr/hr";
    }
    return ""; 
    }

  formatProjectTimeline = (data: Response) => {
    return `${data.project_timeline} ${data.project_timeline_type || "Months"}`;
  }

  handleViewMoreShaow = () => {
    return (this.state.opportunityList.length > 0) ? true: false;
  }

  getListData = () => {
    axios.get(`${configJSON.baseURL.baseURL}/${configJSON.workOpportunityEndPoint}`, { headers: {
      "token": tokenData,
      "Content-Type": "application/json"
    }})
    .then(res => {
      if (res.data.work_opportunities) {
        this.setState({ opportunityList: [...res.data.work_opportunities] });
      }
    }).catch(error => {
      this.setState({
        open: true,
        message: Object.values(error?.response?.data?.errors[0]).join(),
        action: "danger"
      })
    });
  }

  async componentDidMount() {
    super.componentDidMount();
    tokenData = await getToken();
    this.getListData();
  }
  handleOpen = (id: number | string) => {
    this.setState({ openOppertunity: true, selectedOpportunityId: id });   
  };
  handleClose = () => {
    this.setState({ openOppertunity: false });
  }
  handleNavigation = (route: string) => {     
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
  }

  HandleNavigationitem(e:any,items:any) {
    e.stopPropagation()
    let localdata = {
      account_id: items.account_id,
      user_role : items.user_role
    }
    
    setStorageData("PublicId",JSON.stringify(localdata));
    const message: Message =
    new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage),
    "PublicUserProfile");
  message.addData(getName(MessageEnum.NavigationPropsMessage),
  this.props)
  this.send(message);
  }
}

// Customizable Area End