// Customizable Area Start
import React from "react";
import { closeIcon, dancingBar, dropDown, deleteSymbol } from "./../assets";

import MultiRangeSlider from "../../../../components/src/MultiRangeSlider.web";
import {
  customStylesSelect,
  viewMoreForExpertise,
} from "../../../../components/src/Base64Conversion";
import "./../styles.css";

import EditProfileController, { Props } from "./EditProfileController";
import Select from "react-select";
import { styled } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import PlacesAutocomplete from "react-places-autocomplete";
export const configJSON = require("./../config");

class EditProfile extends EditProfileController {
  constructor(props: Props) {
    super(props);
  }

  HandleLanguages =() =>{
    return (
       <div>
              <div className="edit_profile_data_area languageArea">
                <input
                  className="nameInputProfileEdit inputCommanProfileEdit"
                  type="text"
                  id="languageSearchInput"
                  value={this.state.enteredLangQuery}
                  onChange={this.handleInputChangeLanguage}
                  placeholder="Search language"
                />

              </div>
              <div className="edit_profile_subheading ExtraMargin">
                Suggested Languages
              </div>
              <div className="select_list_edit_profile">
                {this.state.languages?.map(
                  (item: any, index: number) =>
                    !!this.state.editProfileData.language?.filter(
                      (i: any) => item.label === i.label
                    ).length && (
                      <button
                        key={item.label}
                        className={`select_button ${"editProfileClickedBtn"} cursorPointer`}
                        id={"language" + index}
                        onClick={() => this.handleLanguageSelect(item)}
                      >
                        <img src={closeIcon} className={`select_close cursorPointer`} />
                        {this.handleCapitalizeFirst(item.label)}
                      </button>
                    )
                )}
                {this.state.filteredOptionsLanguage?.map(
                  (item: any, index: number) =>
                    !this.state.editProfileData.language?.filter(
                      (i: any) => item.label === i.label
                    ).length && (
                      <button
                        key={item.label}
                        className={`select_button cursorPointer`}
                        id={"language" + index}
                        onClick={() => this.handleLanguageSelect(item)}
                      >
                        <img
                          src={closeIcon}
                          className={`select_close ${"displayNone"}`}
                        />
                        {this.handleCapitalizeFirst(item.label)}
                      </button>
                    )
                )}
              </div>
            </div> 
    )
  }
  HandleCompanyProfile = () =>{
    return (
      <>
        {this.state.userRole === "client" && (
          <>
            <div className="user_profile_edit_header addMargin">
              Company Information
            </div>
            <div className="user_profile_edit_client">
              <label htmlFor="profile_name">
                Company Name<span className="required">*</span>
              </label>

              <input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="companyName"
                className="nameInputProfileEdit-client inputCommanProfileEdit-client"
                value={this.getCompanyName()}
                onChange={(e) =>
                  this.updateEditProfileDataWithError(
                    {
                      ...this.state.editProfileData,
                      companyName: e.target.value,
                    },
                    "companyName"
                  )
                }
                onBlur={() =>
                  this.handleBlur("companyName", this.state.editProfileData)
                }
              />
               <div className="editProfileErrorCompany error-text">
                  {this.state.editProfileError.companyName}
                </div>
            </div>
           
            <div className="user_profile_rem_container havingTwoInput">
              <div>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="inputContainerSelect editProfileInputContainerEighth"
                >
                  <label htmlFor="Started" className="Designation_label">
                    Designation
                  </label>
                  <select
                    id={"designation"}
                    className="StartedSelectProfileEdit inputCommanProfileEdit selectProfileEdit"
                    style={{ backgroundImage: `url(${dropDown})` }}
                    value={this.state.editProfileData.designation}
                    onChange={(e) =>
                      this.updateEditProfileDataWithError(
                        {
                          ...this.state.editProfileData,
                          designation: e.target.value,
                        },
                        "designation"
                      )
                    }
                  >
                      <option value="" >
          Select an option
        </option>
                    {this.state.designationList.length > 0 &&
                      this.state.designationList.map(
                        (data: any, index: number) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        }
                      )}
                  </select>
                </div>
              </div>
            </div>
            <div className="user_profile_rem_container havingTwoInput">
              <div>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="inputContainerSelect editProfileInputContainerEighth"
                >
                  <label htmlFor="Started" className="Designation_label">
                    Industry
                  </label>
                  <select
                    id={"industry"}
                    className="StartedSelectProfileEdit inputCommanProfileEdit selectProfileEdit"
                    style={{ backgroundImage: `url(${dropDown})` }}
                    value={this.state.editProfileData.industry}
                    onChange={(e) =>
                      this.updateEditProfileDataWithError(
                        {
                          ...this.state.editProfileData,
                          industry: e.target.value,
                        },
                        "industry"
                      )
                    }
                  >
                     <option value=""  >
          Select an option
        </option>
                    {this.state.industryList.length > 0 &&
                      this.state.industryList.map(
                        (data: any, index: number) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        }
                      )}
                  </select>
                </div>
              </div>
            </div>
            <div
              className="borderLineEditProfile"
              id="borderLineEditProfile2"
            />
          </>
        )}
      </>
    );
  }
  countCharacter =() =>{
    return (
      <>
      <div className="CountCharacter">
        <Typography>
          {this.state.countcharacter}/500
        </Typography>
      </div>
      </>
    )
  }
  PlaceAutoCompleteWrapper = styled(Box)(({ theme }) => ({
    "& .placeAutoComplete": { fontFamily: 'Inter-SemiBold, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px" },
    "& .suggestion_box": { position: 'absolute', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.9)', width: '30%' },
    "& .suggestion_container": { backgroundColor: 'transparent', padding: 2 },
    "& .suggestion": { padding: "3", color: '#FFF2E3', cursor: 'pointer' }
    }));
  render() {
  

    return (
      <EditProfileUi className="user_profile_edit_popup_container">
        <div className="user_profile_edit_popup_overlay" />
        <div className="user_profile_edit_popup">
          <div className="user_profile_edit_popup_padding">
            <button
              id="closeBtnEditProfileId"
              className="closeBtnEditProfile"
              onClick={() => {
                this.handleCancelEditProfile();
              }}
            >
              <img className="cursorPointer" src={closeIcon} />
            </button>
            <div className="user_profile_edit_header">Edit Profile Details</div>
            <div className="user_profile_edit_subheader">
              Boost your profile! Let clients know your expertise.
            </div>
            <div className="user_profile_edit_section">
              <div
                className="user_profile_name_container"
                id="user_profile_name_container3"
              >
                <div className="inputLabelConatiner editProfileInputContainerFirst">
                  {" "}
                  <label htmlFor="profile_name">
                    Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="profile_name"
                    name="name"
                    placeholder="Name"
                    className="nameInputProfileEdit inputCommanProfileEdit"
                    value={this.getNameEditProfile()}
                    onChange={(e) =>
                      this.updateEditProfileDataWithError(
                        {
                          ...this.state.editProfileData,
                          name: e.target.value,
                        },
                        "name"
                      )
                    }
                    onBlur={() =>
                      this.handleBlur("name", this.state.editProfileData)
                    }
                  />
                </div>

                <div className="editProfileError error-text">
                  {this.state.editProfileError.name}
                </div>
              </div>

              <div className="user_profile_rem_container havingTwoInput">
                <div>
                  <div className="inputLabelConatiner editProfileInputContainerSecond">
                    <label htmlFor="profile_website">Website</label>
                    <input
                      type="url"
                      id="profile_website"
                      name="website"
                      placeholder="https://personalwebsite.com"
                      className="inputProfileEdit inputCommanProfileEdit"
                      onChange={(e) =>
                        this.updateEditProfileDataWithError(
                          {
                            ...this.state.editProfileData,
                            website: e.target.value,
                          },
                          "website"
                        )
                      }
                      onBlur={() =>
                        this.handleBlur("website", this.state.editProfileData)
                      }
                      value={this.getwebEditProfile()}
                    />
                  </div>

                  <div className="editProfileError error-text">
                    {this.state.editProfileError.website}
                  </div>
                </div>

                <div>
                  <div className="inputLabelConatiner editProfileInputContainerThird">
                    <label htmlFor="location" className="labelSecond">
                      {configJSON.locationText}
                    </label>
                    <this.PlaceAutoCompleteWrapper>
                    <PlacesAutocomplete
                    data-test-id="placeAuto-data"
                                           value={this.state.location}
                                            onChange={this.handleChangeLocationText}
                                            onSelect={this.handleChangeLocation}
                                            searchOptions={{
                                            types: ['locality']
                                            }}
                                            >
                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => 
                                            (
                                            <Box>
                                            <input
                                            {...getInputProps()}
                                            
                                            onBlur={this.handleChangeLocationTextBlur}
                                            onKeyDown={this.handlePressEnter}
                                            data-test-id="location-data"
                                            className="inputProfileEdit inputCommanProfileEdit"
                                            />
                                            <Box data-test-id="location-description" className="autocomplete-dropdown-container suggestion_box">
                                            {suggestions.map((suggestion, index) => 
                                             (
                                            <Box className="suggestion_container"
                                            {...getSuggestionItemProps(suggestion, { key: { index } })}
                                            >
                                            <Typography  key={index} className="suggestion">{suggestion.description}</Typography>
                                            </Box>
                                            )
                                            )}
                                            </Box>
                                            </Box>
                                            )}
                                            </PlacesAutocomplete>
                    </this.PlaceAutoCompleteWrapper>
                  </div>
                  <div className="editProfileError error-text editProfileErrorSec">
                    {this.state.editProfileError.location}
                  </div>
                </div>
              </div>

              {this.state.userRole === "designer" && (
                <>
                  <div className="user_profile_rem_container havingTwoInput">
                    <div>
                      <div className="inputLabelConatiner editProfileInputContainerFourth">
                        <label htmlFor="dribble">Dribble</label>
                        <input
                          type="url"
                          id="dribble"
                          name="dribble"
                          placeholder="https://personalwebsite.com"
                          className="inputProfileEdit inputCommanProfileEdit"
                          value={this.getDribbleEditProfile()}
                          onChange={(e) =>
                            this.updateEditProfileDataWithError(
                              {
                                ...this.state.editProfileData,
                                dribble: e.target.value,
                              },
                              "dribble"
                            )
                          }
                          onBlur={() =>
                            this.handleBlur(
                              "dribble",
                              this.state.editProfileData
                            )
                          }
                        />
                      </div>
                      <div className="editProfileError error-text">
                        {this.state.editProfileError.dribble}
                      </div>
                    </div>

                    <div>
                      <div className="inputLabelConatiner editProfileInputContainerFifth">
                        <label htmlFor="instagram" className="labelSecond">
                          Instagram
                        </label>
                        <input
                          type="url"
                          id="instagram"
                          name="instagram"
                          placeholder="https://personalwebsite.com"
                          className="inputProfileEdit inputCommanProfileEdit"
                          onChange={(e) =>
                            this.updateEditProfileDataWithError(
                              {
                                ...this.state.editProfileData,
                                insta: e.target.value,
                              },
                              "insta"
                            )
                          }
                          onBlur={() =>
                            this.handleBlur("insta", this.state.editProfileData)
                          }
                          value={this.getinstaEditProfile()}
                        />
                      </div>
                      <div className="editProfileError error-text editProfileErrorSec">
                        {this.state.editProfileError.insta}
                      </div>
                    </div>
                  </div>

                  <div className="user_profile_rem_container">
                    <div className="inputLabelConatiner editProfileInputContainerSixth">
                      <label htmlFor="linkedin">LinkedIn</label>
                      <input
                        type="url"
                        id="linkedin"
                        name="linkedin"
                        placeholder="https://personalwebsite.com"
                        className="inputProfileEdit inputCommanProfileEdit"
                        value={this.getlinkEditProfile()}
                        onChange={(e) =>
                          this.updateEditProfileDataWithError(
                            {
                              ...this.state.editProfileData,
                              linkedin: e.target.value,
                            },
                            "linkedin"
                          )
                        }
                        onBlur={() =>
                          this.handleBlur(
                            "linkedin",
                            this.state.editProfileData
                          )
                        }
                      />
                    </div>
                    <div className="editProfileError error-text">
                      {this.state.editProfileError.linkedin}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="borderLineEditProfile"
              id="borderLineEditProfile1"
            />
            <div className="user_profile_edit_section">
              {this.state.userRole === "designer" ? (
                <>
                  <label htmlFor="about">About Designer</label>
                  <div className="user_profile_edit_subheader user_profile_edit_subheader_about">
                    You can write about your years of experience, industry, or
                    skills. People also talk about their achievements or
                    previous job experiences.
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="about">About Yourself</label>
                  <div></div>
                </>
              )}

              <textarea
                id="about"
                name="about"
                placeholder={configJSON.placeholderAbout}
                className="aboutdesignerTextfield"
                maxLength={500}
                onChange={(e) =>{
                  this.updateEditProfileDataWithError(
                    {
                      ...this.state.editProfileData,
                      about: e.target.value,
                    },
                    "about"
                  )
                  this.handleCountCharacter(e.target.value)
                }
                }
                onBlur={() =>
                  this.handleBlur("about", this.state.editProfileData)
                }
                value={this.getAoutEditProfile()}
              />
            </div>
            {this.countCharacter()}
            <div className="error-text">
              {this.state.editProfileError.about}
            </div>
            <div
              className="borderLineEditProfile"
              id="borderLineEditProfile2"
            />
            {this.HandleCompanyProfile()}
            

            
            {this.state.userRole === "designer" && (
              <>
                <div className="edit_profile_subheading">Rate</div>
                <div>
                  <img src={dancingBar} className="edit_profile_disco_bar" />
                  {!!this.state.editProfileData.rateMax && (
                    <MultiRangeSlider
                      min={0}
                      max={5000}
                      valueMin={this.state.editProfileData.rateMin}
                      valueMax={this.state.editProfileData.rateMax}
                      onChange={this.handleMultiSelect}
                    />
                  )}
                </div>

                <div
                  className="borderLineEditProfile extra_margin"
                  id="borderLineEditProfile3"
                />
                <div className="user_profile_edit_header addMargin">
                  Add Expertise & skills
                </div>
                <div className="user_profile_edit_subheader addMargin">
                  Boost your profile! Let clients know your expertise.
                </div>
                <div className="edit_profile_subheading ExtraMargin">
                  Add Expertise
                </div>
                <div>
                  <div className="edit_profile_data_area expertiseArea">
                    {this.state.expertiseSelected ? (
                      <>
                        {" "}
                        <div className="ExpertiseAreaSelectedExp">
                          {this.state.expertiseSelected.name}
                        </div>{" "}
                        <img
                          src={closeIcon}
                          id="cancelAllExpertiseEditProfile"
                          onClick={this.handleCloseExpertise}
                          className="cursorPointer"
                        />
                      </>
                    ) : (
                      <input
                        className="nameInputProfileEdit inputCommanProfileEdit"
                        type="text"
                        id="expertiseSearchInput"
                        value={this.state.enteredExpQuery}
                        onChange={this.handleInputChangeExpertiseSkills}
                        placeholder="Search Expertise"
                      />
                    )}
                  </div>
                  <div className="select_list_edit_profile user_profile_skills_hide">
                    {this.state.expertiseTempData?.map(
                      (item: any, index: number) =>
                        this.state.expertiseSelected === item && (
                          <button
                            key={"expertise" + index}
                            id={"expertise" + index}
                            className={`select_button ${"editProfileClickedBtn"} cursorPointer`}
                            onClick={(e) => this.expertiseHandler(item)}
                          >
                            <img src={closeIcon} className={`select_close cursorPointer`} />
                            {item.name}
                          </button>
                        )
                    )}
                    {this.state.filteredOptionsExpertise?.map(
                      (item: any, index: number) =>
                        this.state.expertiseSelected !== item && (
                          <button
                            key={"expertise" + index}
                            id={"expertise" + index}
                            className={`select_button cursorPointer`}
                            onClick={() => this.expertiseHandler(item)}
                          >
                            <img
                              src={closeIcon}
                              className={`select_close ${"displayNone"} cursorPointer`}
                            />
                            {item.name}
                          </button>
                        )
                    )}
                  </div>
                </div>
                {!!this.state.skillsTempData.length &&
                  this.state.expertiseSelected && (
                    <>
                      <div className="edit_profile_subheading ExtraMargin">
                        Suggested Skills
                      </div>
                      <div>
                        <div
                          className={`select_list_edit_profile ${this.handleViewMoreCss()}`}
                        >
                          {this.state.skillsTempData?.map(
                            (item: any, index: number) =>
                              this.state.skillsSelected.includes(item.name) && (
                                <button
                                  key={item.name}
                                  id={"skills" + index}
                                  className={`select_button ${"editProfileClickedBtn"} cursorPointer`}
                                  onClick={(e) => {
                                    this.handleSuggestionSkills(item);
                                  }}
                                >
                                  <img
                                    src={closeIcon}
                                    className={`select_close`}
                                  />
                                  {item.name}
                                </button>
                              )
                          )}
                          {this.state.skillsTempData?.map(
                            (item: any) =>
                              !this.state.skillsSelected.includes(
                                item.name
                              ) && (
                                <button
                                  key={item.name}
                                  className={`select_button cursorPointer`}
                                  onClick={() => {
                                    this.handleSuggestionSkills(item);
                                  }}
                                >
                                  <img
                                    src={closeIcon}
                                    className={`select_close ${"displayNone"}`}
                                  />
                                  {item.name}
                                </button>
                              )
                          )}
                        </div>
                      </div>
                      <div>
                        <button
                          className="edit_profile_view_more cursorPointer"
                          id="viewMoreExpertiseId"
                          onClick={this.handleViewMore}
                        >
                          {viewMoreForExpertise(this.state.viewMore)}
                        </button>
                      </div>
                    </>
                  )}

                <div>
                  <button
                    className="edit_profile_add_new"
                    onClick={this.handleAddNewExpertise}
                    disabled={!this.state.expertiseSelected}
                  >
                    Add new
                  </button>
                </div>

                <div
                  className="borderLineEditProfile"
                  id="borderLineEditProfile4"
                />
                <div className="edit_profile_subheading ExtraMargin">
                  Added ({this.state.editProfileData.expertise.length})
                </div>
                {this.state.editProfileData.expertise.map(
                  (item: any, index: number) => (
                    <div>
                      <div className="edit_profile_data_area">
                        <div className="select_list_edit_profile">
                          <div className="heading_box_edit_profile">
                            {item.value?.name}
                          </div>
                          <div
                            className="expertiseDeleteButton"
                            id={"expertisedelete" + index}
                            onClick={() =>
                              this.handleExpertiseDelete(item, index)
                            }
                          >
                            <img src={deleteSymbol} className="cursorPointer" />
                          </div>
                          <div
                            className={`add_section_edit_profile_skills ${this.handleEditProfileSKillsCss(
                              item
                            )}`}
                          >
                            {" "}
                            {item?.skills?.map(
                              (itemSkill: any, indexSkill: number) =>
                                itemSkill.select && (
                                  <button
                                    key={itemSkill.value}
                                    id={"itemSkills" + indexSkill}
                                    className={`select_button ${"editProfileClickedBtn"} cursorPointer`}
                                    onClick={() => {
                                      this.handleSkillsInAddedList(
                                        index,
                                        indexSkill
                                      );
                                    }}
                                  >
                                    <img
                                      src={closeIcon}
                                      className="select_close"
                                    />
                                    {itemSkill?.value}
                                  </button>
                                )
                            )}
                            {item?.skills?.map(
                              (itemSkill: any, indexSkill: number) =>
                                !itemSkill.select && (
                                  <button
                                    key={itemSkill.value}
                                    id={"addedSkills" + indexSkill}
                                    className={`select_button cursorPointer`}
                                    onClick={() => {
                                      this.handleSkillsInAddedList(
                                        index,
                                        indexSkill
                                      );
                                    }}
                                  >
                                    {itemSkill?.value}
                                  </button>
                                )
                            )}
                          </div>
                        </div>
                        {
                          <div>
                            <button
                              className="edit_profile_view_more editProfileTextUnderLine cursorPointer"
                              data-testId="view-more-skills"
                              onClick={() => this.handleSkillViewMore(index)}
                            >
                              {this.viewMoreText(index)}
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  )
                )}

                <div
                  className="borderLineEditProfile"
                  id="borderLineEditProfile5"
                />

                <div className="edit_profile_subheading ExtraMargin">
                  Education
                </div>
                <div className="editProfileEDheadingDis addMargin editEducation editProfileMarginExtra">
                  Edit your education details
                </div>
                {this.state.editProfileData.education.map(
                  (item: any, index: number) => (
                    <div key={index} className="user_profile_edit_section">
                      <div className="editProfileEdSubHeading addMargin editProfileMarginExtra">
                        Education {index + 1}
                      </div>
                      <div
                        className="user_profile_name_container"
                        id="user_profile_name_container1"
                      >
                        <div
                          className="UserProfiledeleteButtonWrapper"
                          id={"educationDelete" + index}
                          onClick={() => {
                            this.handleEducationDelete(index,item);
                          }}
                        >
                          <img src={deleteSymbol} className="cursorPointer"/>
                        </div>
                        <div className="inputLabelConatiner editProfileInputContainerSeventh">
                          <label
                            htmlFor="Organization"
                            className="education_label"
                          >
                            Organization
                          </label>
                          <input
                            type="text"
                            id={"Organization" + index}
                            name="Organization"
                            placeholder="Organization"
                            value={
                              this.state.editProfileData.education[index]
                                ?.qualification
                            }
                            className="educationInputProfileEdit inputCommanProfileEdit"
                            onBlur={() =>
                              this.handleBlurEducation(index, "qualification")
                            }
                            onChange={(e) => {
                              this.state.editProfileData.education[
                                index
                              ].qualification = e.target.value;
                              this.updateEditProfileDataWithErrorEd(
                                {
                                  ...this.state.editProfileData,
                                },
                                index,
                                "qualification"
                              );
                            }}
                          />
                        </div>
                        <div className="editProfileError error-text editProfileErrorSec">
                          {
                            this.state.editProfileError.education[index]
                              ?.qualification
                          }
                        </div>
                      </div>
                      <div className="user_profile_rem_container havingTwoInput">
                        <div>
                          <div className="inputLabelConatiner editProfileInputContainerEighth">
                            <label
                              htmlFor="Started"
                              className="education_label"
                            >
                              Started in
                            </label>
                            <select
                              id={"Started" + index}
                              className="StartedinputProfileEdit inputCommanProfileEdit selectProfileEdit"
                              style={{ backgroundImage: `url(${dropDown})` }}
                              value={
                                this.state.editProfileData.education[index]
                                  ?.year_from
                              }
                              onChange={(e) => {
                                this.state.editProfileData.education[
                                  index
                                ].year_from = e.target.value;
                                this.updateEditProfileDataWithErrorEd(
                                  {
                                    ...this.state.editProfileData,
                                  },
                                  index,
                                  "year_from"
                                );
                              }}
                              onBlur={() =>
                                this.handleBlurEducation(index, "year_from")
                              }
                            >
                              {this.getYearList()?.map((year: any) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="editProfileError error-text editProfileErrorSec editProfileEducationError">
                            {this.checkErrorEditProfile(index) &&
                              this.state.editProfileError.education[index]
                                ?.year_from}
                          </div>
                        </div>

                        <div>
                          <div className="inputLabelConatiner editProfileInputContainerNinth">
                            <label htmlFor="Ended" className="labelSecond">
                              Ended in
                            </label>
                            <select
                              id={"Ended" + index}
                              className="StartedinputProfileEdit inputCommanProfileEdit selectProfileEdit"
                              style={{ backgroundImage: `url(${dropDown})` }}
                              value={
                                this.state.editProfileData.education[index]
                                  ?.year_to
                              }
                              onChange={(e) => {
                                this.state.editProfileData.education[
                                  index
                                ].year_to = e.target.value;
                                this.updateEditProfileDataWithErrorEd(
                                  {
                                    ...this.state.editProfileData,
                                  },
                                  index,
                                  "year_to"
                                );
                              }}
                              onBlur={() =>
                                this.handleBlurEducation(index, "year_to")
                              }
                            >
                              {this.getYearList()?.map((year: any) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="editProfileError error-text editProfileErrorSec editProfileEducationErrorEndYear">
                            {this.checkErrorEditProfile(index) &&
                              this.state.editProfileError.education[index]
                                ?.year_to}
                          </div>
                        </div>
                      </div>

                      <div
                        className="user_profile_name_container editProfileMarginBottom"
                        id="user_profile_name_container2"
                      >
                        <div className="inputLabelConatiner editProfileInputContainertenth">
                          <label htmlFor="Course" className="education_label">
                            Course
                          </label>
                          <input
                            type="text"
                            id={"Course" + index}
                            name="Course"
                            placeholder="Course"
                            className="educationInputProfileEdit inputCommanProfileEdit"
                            value={
                              this.state.editProfileData.education[index]
                                ?.description
                            }
                            onChange={(e) => {
                              this.state.editProfileData.education[
                                index
                              ].description = e.target.value;
                              this.updateEditProfileDataWithErrorEd(
                                {
                                  ...this.state.editProfileData,
                                },
                                index,
                                "description"
                              );
                            }}
                            onBlur={() =>
                              this.handleBlurEducation(index, "description")
                            }
                          />
                        </div>
                        <div className="editProfileError error-text editProfileErrorSec editProfileEducationError">
                          {
                            this.state.editProfileError?.education[index]
                              ?.description
                          }
                        </div>
                      </div>
                    </div>
                  )
                )}

                <div>
                  <button
                    className="edit_profile_add_new education_add_new addButtonMargin"
                    id="add_education"
                    onClick={() => this.handleAddClick()}
                  >
                    Add new
                  </button>
                </div>
                <div
                  className="borderLineEditProfile"
                  id="borderLineEditProfile6"
                />
              </>
            )}

            <div className="user_profile_edit_header addMargin">Language</div>
            {this.HandleLanguages()}
            
          </div>
          <div className="editProfileCancelSaveButtonContiner">
            <button
              className="saveButtonEditProfile cancelButtonEditProfile"
              onClick={this.handleCancelEditProfile}
            >
              Cancel
            </button>
            <button
              className="saveButtonEditProfile rippleEffectEditProfile"
              onClick={this.handleSaveEditProfile}
              disabled={this.getErrorValuesAsString()}
              data-test-id="save-edit"
            >
              Save edits
            </button>
          </div>

          <div className="addextraspace" />
        </div>
      </EditProfileUi>
    );
  }
}

export default EditProfile;

const EditProfileUi = styled(Box)({
  "& .addButtonMargin":{
    marginTop: "10px"
  },
  "& .editEducation":{
    marginBottom: "25px"
  }
})
// Customizable Area End
