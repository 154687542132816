import React from "react";
// Customizable Area Start
import { Box, Button,IconButton, Typography, Modal,styled, CircularProgress } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { configJSON } from "./CatalogueController";
import { createTheme} from "@material-ui/core/styles";
import ProfileCard from "../../../components/src/ProfileCard.web"
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderProfileCards = () =>{
    return this.state.profileDataList.map((item:any,index:number) => {
      return (
        <div data-testid="Navigatediv"  onClick={()=>this.HandleNavigations(item)}>
        <ProfileCard completedSkills={[]} handleNavigation={this.handleNavigation} data={item.attributes} handleOpen={this.handleOpen} isOpen={this.state.isOpen} index={index} />
        </div>
      )
     
    })
  }

  CustomModal = styled(Modal)(({theme})=>({
    display:'flex',justifyContent:'center',alignItems:'center',height:'100vh',width:'100vw',
    "& .close_button": {
      display:'flex',justifyContent:'flex-end'
    }, "& .close_button_icon": {
      color:'white', right: -1,
                    position: "absolute",
                    marginBottom: 10,
                    fontSize: "18px"
    },"& .backgroundColor":{
      backgroundColor:"#222222"
    }
  }))

  MainWrapper = styled(Box)(({ theme }) => ({
    "& .main_container": {
      padding: "0 48px"
    },
    "& .loader_box":{
      width:394 , height:180, borderRadius:12, display:'flex', justifyContent:"center", alignItems:'center'
    }, "& .loader":{
      color:"white"
    },
    "& .heading_container": {
      display:'flex',justifyContent:"space-between"
    },
    "& .heading": {
      color: "#fff2e3", fontSize: 36, fontWeight:600,fontFamily: "Inter, sans-serif"
    },
    "& .view_more": {
      color: "#fff2e3", textTransform:'none',textDecoration:'underline',fontFamily: "Inter, sans-serif"
    },
    "& .skills_main_container": {
      display:"flex", gap:60, overflow:'hidden', overflowX:'scroll', marginTop:30,
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none', 
    },
    "& .container2": {
      borderLeft:"0.5px solid white",padding:'0 20'
    },"& .modal_main_container": {
      display:'flex',justifyContent:'center',alignItems:'center',height:'100vh',width:'100vw'
    },
    "& .stats_heading": {
      color:'#7D7D7D', fontSize:14
    },
    "& .stats_result": {
      color:'#FFF2E3', fontSize:16, fontWeight:500
    }, "& .skills_container": {
      display: 'flex', flexWrap: 'wrap', padding: "20px", width:'90%', gap:5    },
    "& .skills": {
      backgroundColor: '#1D1D1D', 
            color: '#FFF2E3', 
            fontSize: 14, 
            fontWeight: 400,
            cursor: 'pointer',
            margin: '2px 0px',
            transition: 'opacity 3s ease-in-out, transform 3s ease-in-out'
    },
    "& .skills_count": {
      color: '#FFF2E3', 
              fontSize: 14, 
              fontWeight: 400,
              cursor: 'pointer',
              margin: '2px 0px',
              transition: 'opacity 3s ease-in-out, transform 3s ease-in-out'
    }, "& .cover_image":{
      width:'100%',
      height:74.98,
      borderRadius:"11.54px 11.54px 0px 0px"

  }, "& .cover_image_blank":{
    backgroundColor:'black',
    borderRadius:"11.54px 11.54px 0px 0px"
},

[theme.breakpoints.down(1878)]: {
  "& .skills_main_container": {
    gap:42
  },
},
[theme.breakpoints.down(1822)]: {
  "& .skills_main_container": {
    gap:30
  },
},
  }));


  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      // <ThemeProvider theme={theme}>
      //   <Container maxWidth="md">
      //   </Container>
      // </ThemeProvider>

      <this.MainWrapper>
      <Box className="main_container">
        <Box className="heading_container">
          <Typography className="heading" >Find Designers: Post Your Opportunity</Typography>
          <Button className="view_more" data-test-id="view_more_button" onClick={()=>this.handleNavigation("PostOpportunity")} variant="text">View more</Button>
        </Box>
        <div onScroll={this.handleScroll} ref={this.itemsContainerRef} className="skills_main_container" data-test-id="profile_container">
          {this.renderProfileCards()}
          { this.state.isLoading && !this.state.isFirstTimeLoading && <Box className="loader_box"> 
            <CircularProgress className="loader" />
          </Box> }
        </div>
        <this.CustomModal
          open={this.state.isOpen}
          className="modal"
          data-test-id="profile_modal"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className="modal_main_container">
            <Box>
              <Box className="close_button">
                <IconButton onClick={this.handleClose} data-test-id="profile_modal_cross">
                  <CloseIcon className="close_button_icon" />
                </IconButton> 
              </Box>
              {
                this.state.openedData?.skills.length ? <ProfileCard handleNavigation={this.handleNavigation} completedSkills={this.state.openedData.skills} data={this.state.openedData} handleOpen={this.handleOpen} isOpen={this.state.isOpen} index={0}/> : <ProfileCard handleNavigation={this.handleNavigation} completedSkills={[]} data={this.state.openedData} handleOpen={this.handleOpen} isOpen={this.state.isOpen} index={0}/>
              }
              
            </Box>
          </Box>
        </this.CustomModal>
      </Box>
      </this.MainWrapper>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
