// Customizable Area Start
import React from 'react';
import ToastMSG from '../../../../components/src/ToastMSG.web';
import { FormControlLabel, withStyles, Radio, TextField, RadioGroup, Checkbox, Box, styled, Typography, Input, Button, InputLabel, Modal, Grid, Divider } from '@material-ui/core';
import SingleSelect from '../../../../components/src/SingleSelect.web';
import { deleteIcon, searchIcon, closeIcon, PostOpportunityBanner, leftArrowIcon, dropDownIcon, eyeIcon, featureIcon, likeIcon, checkIcon, uncheckIcon, likeIcon2, location } from '../assets';
import NavigationMenu from '../../../navigationmenu/src/NavigationMenu.web';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PlacesAutocomplete from 'react-places-autocomplete';
import dayjs, { Dayjs } from "dayjs";
import PostOpportunityController, { ExperienceLevel, Props, Response, WorkType, configJSON } from './PostOpportunityController';
const config = require("../../../../framework/src/config.js")

const customStyle = require('../CustomStyles.js');

const theme = createTheme({
    palette: {
        primary: {
            main: '#fff2e3',
        }
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#222',
                    color: '#fff2e3'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter,sans-serif !important',
                    color: '#fff2e3 !important',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#fff2e3 !important'
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter,sans-serif !important',
                    color: '#7d7d7d !important',
                    '&.Mui-selected': {
                        color: '#222 !important',
                        backgroundColor: '#fff2e3 !important'
                    },
                    '&.MuiPickersDay-today': {
                        border: '1px solid #fff2e3 !important'
                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0, 
                    paddingBottom: 0,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflowX: "hidden",
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        width: '0',
                        height: '0',
                    }
                }
            }
        }
    },
});

const TextFieldData = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            height: "48.68px",
            borderColor: '#66625C',
            borderWidth: "1.62px",
            borderRadius: "8.11px",
            '& fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            },
            '&:hover fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            },
            '&.Mui-focused fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            }
        },
        '& p.MuiFormHelperText-root': {
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#FFF2E3",
            fontFamily: "Inter, sans-serif"
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":{
            borderColor: "#66625C"
        }
    },
})(TextField);

const CustomRadio = withStyles({
    root: {
        color: '#fff2e3',
        '&.MuiTypography-body1': {
            color: '#fff2e3'
        }
    },
    checked: {
        '&.Mui-checked': {
            color: '#fff2e3'
        }
    },

})(Radio);

const CustomCheckBox = withStyles({
    root: {
        color: '#d9d9d9',
        width: '20px',
        height: '20px',
        borderRadius: '2.3px',
        border: 'none'
    },
    checked: {
        '&.Mui-checked': {
            color: '#111111'
        }
    },

})(Checkbox);

const CustomTextField = withStyles({
    root: {
        ...customStyle.customStyle.inputField
    }
})(Input);

const CustomButton = withStyles({
    root: {
        ...customStyle.customStyle.buttonField
    }
})(Button);

const CustomBox = withStyles((theme) => ({
    root: {
        '&.project-reference-modal': {
            backgroundColor: '#222',
            borderRadius: '11.97px',
            padding: '53px 63px',
            height: '742px',
            width: '1072px',
            display: 'flex',
            justifyContent: "space-between",
            '& .modal-header-data': {
                display: 'flex',
                justifyContent: 'space-between'
            },
            '& .back-btn': {
                marginTop: '4px',
                alignSelf: 'flex-start'
            },
            '& .secondary-text': {
                color: "#fff2e3"
            },
            '& .disabled-text': {
                color: "#7d7d7d"
            },
            '& .uncheck-elm': {
                right: "14px",
                top: "14px"
            },
            '& .check-elm': {
                right: "10px",
                top: "10px"
            },
            '& .check-elm, & .uncheck-elm': {
                position: "absolute",
                zIndex: 99
            },
            '& .modal-sub-heading': {
                display: 'flex',
                gap: '54px',
                paddingBottom: '10px',
                marginTop: '12px',
                marginRight: '14px',
                borderBottom: '2.33px solid #2F2F2F',
                color: '#fff2e3',
                marginBottom: '32px'
            },
            '& .accordian-elm': {
                borderRadius: '16px',
                backgroundColor: '#171717',
                border: '1px solid #535353',
                minHeight: '36px',
                padding: '16px',
                marginBottom: '10px'
            },
            '& .accordian-summary': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            '& .right-elm': {
                display: 'flex',
                alignItems: 'center',
                gap: '16px'
            },
            '& .accordian-collapse': {
                height: '36px',
                width: '36px',
                minWidth: '36px',
                backgroundColor: '#222',
                borderRadius: '8px',
                border: 'none'
            },
            '& .button-text': {
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.38px',
                fontFamily: 'Inter-Medium, sans-serif',
                color: '#fff2e3',
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'center'
            },
            '& .button-text-count':{
                fontSize: '16px',
                letterSpacing : '0.38px',
                lineHeight: '24px',
                color:'#fff2e3',
                fontFamily: 'Inter-Medium, sans-serif',
                alignSelf:'center',
                justifyContent:'flex-end',
                display:'flex'
            },
            '& .toggle-accordian-up': {
                rotate: "180deg"
            },
            '& .add-btn': {
                width: '92px'
            },
        },
        '&.project-reference-modal,&.post-opportunity-container': { 
            '& .card-container': {
                marginTop: '32px',
                display: 'flex',
                rowGap: '32px',
                columnGap: '24px',
                justifyContent: 'flex-start',
                flexWrap: 'wrap !important'
            },
            '& .card-style': {
                width: '380px',
                height: '260px',
                borderRadius: '10px',
                marginBottom: '8px',
                position: 'relative'
            },
            '& .manage-space-feature, & .manage-space': {
                display: 'flex',
                fontFamily: 'Inter-SemiBold, sans-serif',
                fontSize: '11px',
                lineHeight: '13.31px',
                color: '#fff2e3',
                alignItems: 'center',
                maxHeight: '22px'
            },
            '& .card-label, & .card-text': {
                width: '165px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#fff2e3'
            },
            '& .card-label': {
                fontFamily: 'Inter-SemiBold, sans-serif',
                fontSize: '14px',
                lineHeight: '16.94px',
                marginBottom: '8px'
            },
            '& .manage-space-feature': {
                gap: "2px"
            },
            '& .manage-space': {
                gap: "8px"
            },
            '& .manage-gap': {
                gap: "19px"
            },
            '& .card-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '12px',
                lineHeight: '14.52px'
            },
            '& .default-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '16px',
                lineHeight: '19.36px'
            },
            '& .text-elm': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '20px',
                lineHeight: '24.2px',
                color: '#fff2e3',
            },
            '& .bold-text': {
                fontFamily: 'Inter-SemiBold, sans-serif !important',
            },
            '& .d-none': {
                display: 'none',
            },
        },
        '&.post-opportunity-container': {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '48px',
            paddingRight: '48px',
            paddingBottom: '50px',
            height: 'auto',
            marginTop: '76px',
            alignItems: 'center',
            '& .default-radius': {
                borderRadius: "11.97px"
            },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderBottom: "none !important"
            },
            '& .additional-resource-container': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '11.97px',
                border: '2.33px dashed #0D0D0D',
                paddingTop: '13px',
                width: '427px',
                height: '276px'
            },
            '& .bg-gray': {
                backgroundColor: "#222"
            },
            '& .d-flex': {
                display: "flex"
            },
            '& .flex-column': {
                display: "flex",
                flexDirection: "column"
            },
            '& .secondary-color': {
                color: '#fff2e3'
            },
            '& .hint-color': {
                color: "#696969"
            },
            '& .add-question': {
                width: "108px",
                marginTop: "30px"
            },
            '& .label-text': {
                fontFamily: 'Inter-SemiBold, sans-serif',
                fontSize: '20px',
                lineHeight: '24.2px',
                color: '#fff2e3',
                margin: 0,
                padding: 0
            },
            '& .hint-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
                lineHeight: '16.94px'
            },
            "& .word-wrap":{
                overflowWrap: "anywhere"
            },
            '& .main-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '15px',
                lineHeight: '18.15px',
                color: '#fff2e3'
            },
            '& .hours-container .option-container': {
                left: '-80px'
            },
            '& .disabled-text': {
                color: '#7d7d7d',
            },
            '& .horizontal-scroll': {
                justifyContent: 'flex-start !important',
                flexWrap: 'nowrap !important',
                overflowX: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            },
            '& .flex-data': {
                display: 'flex',
                gap: '24px',
            },
            '& .filename-container': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .filename-container-elm': {
                color: '#fff',
                width: 'max-content',
                borderRadius: '10px',
                backgroundColor: '#252525',
                padding: '13px 15px',
            },
            '& .list-no': {
                color: '#7d7d7d',
                paddingRight: '12px',
                fontFamily: 'Inter-SemiBold, sans-serif !important',
                fontSize: '20px',
                lineHeight: '24.2px',
            },
            ...customStyle.customStyle.tagsElm
        },
        '&.post-opportunity-container ::placeholder': {
            fontStyle: 'italic',
        },
        '&.post-opportunity-container .main-text::placeholder, &.post-opportunity-container .date-container input::placeholder': {
            color: '#686868',
        },
        '&.post-opportunity-container .h-63': {
            height: '63px',
        },
        '&.post-opportunity-container .link-text::placeholder': {
            color: '#808080',
        },
        '&.post-opportunity-container .rateData::placeholder': {
            color: '#595959',
        },
        '&.post-opportunity-container .question-container': {
            borderTop: '2.33px solid #2F2F2F',
            borderBottom: '2.33px solid #2F2F2F',
            padding: '55px 60px',
            backgroundColor: '#171717',
        },
        '&.post-opportunity-container .normal-style input::placeholder': {
            fontStyle: 'normal !important',
        },
        '&.post-opportunity-container .add-resource, &.post-opportunity-container .upload-resource': {
            padding: '0',
            height: 'max-content !important',
            borderBottom: '1px solid #fff2e3',
            backgroundColor: 'transparent',
            borderRadius: '0',
            color: '#fff2e3',
            width: 'max-content',
        },
        '&.post-opportunity-container .upload-resource': {
            marginTop: '38px',
        },
        '&.post-opportunity-container .draft-btn': {
            width: '142px',
        },
        '&.post-opportunity-container .medium-text': {
            fontFamily: 'Inter-Medium, sans-serif !important',
        },
        '&.post-opportunity-container .underline-text': {
            width: 'max-content',
            lineHeight: '1.4 !important',
            textDecoration: 'underline'
        },
        '&.post-opportunity-container .date-container fieldset': {
            outline: 'none',
            border: 'none',
        },
        '&.post-opportunity-container .date-container input': {
            color: '#fff2e3',
        },
        '&.post-opportunity-container .date-container button': {
            color: '#fff2e3',
        },
        '&.post-opportunity-container .select-btn': {
            width: '209px',
            height: '37px',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
            color: '#fff2e3',
            border: '1px solid #66625c',
            borderRadius: '6px',
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            lineHeight: '14px',
            letterSpacing: '0.38px',
        },
        '&.post-opportunity-container .select-btn img.moveup': {
            rotate: '180deg',
        },
        '&.post-opportunity-container .right-data-elm': {
            display: 'flex',
            gap: '18px',
        },
        '&.post-opportunity-container .form-container': {
            padding: '40px 60px',
            backgroundColor: '#171717',
            display: 'flex',
            flexDirection: 'column',
            gap: '100px',
        },
        '&.post-opportunity-container .form-container, &.post-opportunity-container .question-container': {
            maxWidth: '1103px',
            width: '100%',
        },
        '&.post-opportunity-container .search-field': {
            width: '100%',
            backgroundColor: 'transparent',
            border: 'none',
            height: '49px',
            color: '#fff2e3',
        },
        '&.post-opportunity-container .header-container': {
            display: 'flex',
            width: '100%',
            maxWidth: '1348px',
            justifyContent: 'space-between',
            marginLeft: '-71px',
        },
        '&.post-opportunity-container .link-container': {
            width: '405px',
            border: '1.16px solid #3C3C3C',
            borderRadius: '10px',
        },
        '&.post-opportunity-container .link-text': {
            height: '57px !important',
            textAlign: 'center',
            borderRadius: '10px',
            color: '#fff2e3',
        },
        '&.post-opportunity-container .link-text input': {
            textAlign: 'center !important'
        },
        '&.post-opportunity-container .formGroup': {
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
        },
        '&.post-opportunity-container .hours-container': {
            display: 'flex',
            alignItems: 'center',
            height: '50px',
            backgroundColor: '#222',
            borderRadius: '10px',
            width: '223px',
            marginTop: '10px'
        },
        '&.post-opportunity-container .hours-container .single-select-container .select-btn': {
            padding: '0',
            height: 'max-content',
            width: '63px',
            borderRadius: '0',
            border: 'none',
        },
        '&.post-opportunity-container .hours-container .single-select-container .title-elm': {
            width: 'max-content',
            borderBottom: '1px solid #fff2e3',
        },
        '&.post-opportunity-container .hours-container .single-select-container .value-elm': {
            display: 'none',
        },
        '&.post-opportunity-container .location-container .single-select-container .select-btn': {
            width: '156px',
        },
        '&.post-opportunity-container .location-container .single-select-container .title-elm': {
            width: 'max-content',
        },
        '&.post-opportunity-container .location-container .single-select-container .value-elm': {
            display: 'none',
        },
        '&.post-opportunity-container .location-container': {
            width: 'max-content',
        },
        '&.post-opportunity-container .discard-container': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '&.post-opportunity-container .discard-container .discard-text': {
            fontSize: '16px',
            fontFamily: 'Inter-SemiBold, sans-serif',
            lineHeight: '19.36px',
            color: '#fff2e3',
            borderBottom: '1px solid #fff2e3',
            width: 'max-content',
            marginLeft: '18px',
        },
        '&.post-opportunity-container .project-title, &.post-opportunity-container .description-title, &.post-opportunity-container .deliverables-title': {
            border: 'none',
            backgroundColor: '#222',
            borderRadius: '11.97px',
            paddingLeft: '27px',
            paddingRight: '27px',
            fontFamily: 'Inter, sans-serif',
            fontSize: '15px',
            lineHeight: '18.15px',
        },
        '&.post-opportunity-container .description-title': {
            padding: '27px',
            maxWidth: '952px',
            height: '163px',
            resize: 'none',
        },
        '&.post-opportunity-container .limit-title': {
            maxWidth: '952px',
            color: '#fff2e3',
            textAlign: 'end',          
            marginTop: '-20px'
        },
        '&.post-opportunity-container .project-title': {
            height: '77px',
            maxWidth: '466px',
        },
        '&.post-opportunity-container .deliverables-title': {
            padding: '26px 16px !important',
            maxWidth: '952px',
            height: '145px',
            resize: 'none',
        },
        '&.post-opportunity-container .work-type-container': {
            borderRadius: '11.97px',
            backgroundColor: '#222',
            display: 'flex',
            flexDirection: 'column',
            gap: '43px',
            padding: '28px 0px',
            paddingLeft:"37px",
            maxWidth: '915px',
        },
        '&.post-opportunity-container .work-type-container .text-container': {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '915px',
        },
        '&.post-opportunity-container .project-title:focus, &.post-opportunity-container .description-title:focus, &.post-opportunity-container .search-field:focus, &.post-opportunity-container .deliverables-title:focus, &.post-opportunity-container .link-text:focus, &.post-opportunity-container .question-data input:focus': {
            outline: 'none',
        },
        '&.post-opportunity-container .upload-container': {
            display: 'flex',
            width: '405px',
            height: '115px',
            justifyContent: 'center',
            backgroundColor: '#222',
            borderRadius: '10px',
        },
        '&.post-opportunity-container .heading-text': {
            fontSize: '36px',
            lineHeight: '43.57px',
            color: '#fff2e3',
            fontFamily: 'Inter-SemiBold, sans-serif',
        },
        '&.post-opportunity-container .option-container-collection .menu-item': {
            color: '#fff2e3',
            cursor: 'pointer',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            lineHeight: '19.36px',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            borderTop: '1px solid #5f5f5f',
            zIndex: '12',
            backgroundColor: '#171717',
        },
        '&.post-opportunity-container .option-container-collection .menu-item.active': {
            backgroundColor: '#222',
        },
        '&.post-opportunity-container .option-container-category, &.post-opportunity-container .option-container-collection': {
            borderRadius: '0 0 10px 10px',
            border: '1px solid #66625c',
            borderTop: 'none',
            position: 'absolute',
            width: '207px',
            maxHeight: '205px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
        },
        '&.post-opportunity-container .option-container-category::-webkit-scrollbar, &.post-opportunity-container .option-container-collection::-webkit-scrollbar, &.post-opportunity-container .horizontal-scroll::-webkit-scrollbar': {
            width: '0',
            height: '0',
        },
        '&.post-opportunity-container .menu-item:last-child': {
            borderRadius: '0 0 10px 10px',
        },
        '&.post-opportunity-container .link-text-data': {
            color: '#528ADF',
        },
        '&.post-opportunity-container .no-border': {
            border: 'none !important',
        },
        '&.post-opportunity-container .menu-open': {
            borderRadius: '10px 10px 0 0',
            height: '40px',
            border: '1px solid #5f5f5f',
            borderBottom: 'none',
        },
        '&.post-opportunity-container .select-container': {
            position: 'relative',
        },
        '&.post-opportunity-container .search-container': {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            paddingLeft: '22px',
            paddingRight: '22px',
        },
        '&.post-opportunity-container .skill-container': {
            borderRadius: '16.5px',
            border: '1px solid #323232',
            maxWidth: '952px',
        },
        '&.post-opportunity-container .skill-Suggestion': {
            fontSize: '12px',
            color: '#666',
            lineHeight: '14.52px',
            fontFamily: 'Inter, sans-serif',
            marginBottom: '16px',
            paddingTop: '22px',
            borderTop: '1px solid #2f2f2f',
            paddingLeft: '22px',
        },
        '&.post-opportunity-container .active-skill-list-container': {
            paddingTop: '16px',
            marginBottom: '8px',
        },
        '&.post-opportunity-container .skill-list-container, &.post-opportunity-container .active-skill-list-container': {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
            paddingLeft: '22px',
            paddingRight: '22px',
            marginBottom: '22px',
            overflow: 'auto',
            maxHeight: '100px',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
        },
        '&.post-opportunity-container .skill-list-container::-webkit-scrollbar, &.post-opportunity-container .active-skill-list-container::-webkit-scrollbar': {
            width: '0',
            height: '0',
        },
        '&.post-opportunity-container .tags': {
            width: 'max-content',
        },
        '&.post-opportunity-headerData': {
            display: 'flex',
            flexDirection: 'column',
        },
        '&.post-opportunity-container .banner-data': {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: '446px',
            marginTop: '76px',
        },
        '&.post-opportunity-container .discardBtn': {
            fontFamily: 'Inter-SemiBold, sans-serif',
            fontSize: '16px',
            lineHeight: '19.36px',
            height: 'max-content',
            width: 'max-content',
            borderBottom: '1px solid #fff2e3',
            color: '#fff2e3',
            padding: '0',
            backgroundColor: 'transparent',
            border: 'none',
        },
        '&.post-opportunity-container .create-project:active': {
            opacity: '0.5',
        },
        '&.post-opportunity-container .pointer-cursor': {
            cursor: 'pointer',
        },
        '&.post-opportunity-container .question-data': {
            maxWidth: '908px',
            width: '100%',
            height: '74px',
            borderRadius: '11.97px',
            backgroundColor: '#222',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
            justifyContent: 'space-between',
        },
        '&.post-opportunity-container .mt-50': {
            marginTop: '50px',
        },
        '&.post-opportunity-container .mt-16': {
            marginTop: '16px',
        },
        '&.post-opportunity-container .question-data input': {
            height: '74px',
            backgroundColor: 'transparent',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            lineHeight: '21.2px',
            width: '95%',
            color: '#fff2e3',
            border: 'none',
        },
        '&.post-opportunity-container .flex-center': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&.post-opportunity-headerData .fixed-top': {
            position: 'fixed',
            width: '100%',
            zIndex: '999',
        },
        '&.post-opportunity-container .location-container .option-container>div, &.post-opportunity-container .hours-container .option-container>div': {
            width: '156px !important',
        },
        '&.parent-element': {
            backgroundColor: "#111111"
        },
        [theme.breakpoints.down(1440)]: {
            '&.post-opportunity-container': {
                '& .form-container,& .question-container': {
                    maxWidth: "811px"
                },
                '& .header-container': {
                    maxWidth: "1081px",
                    marginLeft: "-90px"
                }
            }
        }
    }
}))(Box);

const PlaceAutoCompleteWrapper = styled(Box)({
    "& .placeAutoComplete": { fontFamily: 'Inter-SemiBold, sans-serif', color: '#FFF2E3', fontSize: "14px", lineHeight: "16.94px" },
    "& .suggestion_box": { position: 'absolute', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.9)', width: 800 },
    "& .suggestion_container": { backgroundColor: 'transparent', padding: 2 },
    "& .suggestion": { padding: "3", color: '#FFF2E3', cursor: 'pointer' }
});

class PostOpportunity extends PostOpportunityController {
    constructor(props: Props) {
        super(props);
    }

    renderWorkTypeArray = configJSON.workTypeArray.map((elem: WorkType, index: number) => {
        return <FormControlLabel
        key={index}
        data-test-id='btnToggle1'
        style={{ alignItems: 'flex-start', margin: 0 }}
        value={elem.value}
        control={<CustomRadio style={{ marginRight: '12px', padding: 0 }} />}
        label={<CustomBox className='text-container'>
            <Typography className='default-text secondary-color'>{elem.name}</Typography>
            <Typography className='hint-text hint-color'>{elem.details}</Typography>
        </CustomBox>}
        onClick={() => { this.handleWorkType(elem.value) }}
    />
    })

    renderExperienceArray = configJSON.experienceArray.map((elem: ExperienceLevel, index: number) => {
        return <FormControlLabel
        key={index}
        data-test-id='btnToggle1'
        style={{ alignItems: 'flex-start', margin: 0 }}
        value={elem.value}
        control={<CustomRadio style={{ marginRight: '12px', padding: 0 }} />}
        label={<Typography className='default-text margin secondary-color'>{elem.name}</Typography>}
        onClick={() => { this.handleWorkExperience(elem.value) }}
    />
    })

    renderElement = (item:Response) =>{
        return  <CustomBox style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CustomBox>
            <Typography className='card-label' title={item.project_name}>{item.project_name}</Typography>
            <Typography className='card-text'>{item.username}</Typography>
        </CustomBox>
        <CustomBox className='manage-gap' style={{ display: 'flex', height: '24px' }}>
            <CustomBox className='manage-space'>
                <Typography>{item.views}</Typography>
                <img className='eyeIcon' src={eyeIcon} alt='eye icon' />
            </CustomBox>
            <CustomBox className='manage-space'>
                <Typography>{item.likes}</Typography>
                <img className='likeIcon' src={likeIcon} alt='like icon' />
            </CustomBox>
            <CustomBox className='manage-space-feature'>
                <Typography>{item.saved_count}</Typography>
                <img className='featureIcon' src={featureIcon} alt='feature icon' />
            </CustomBox>
        </CustomBox>
    </CustomBox>
    }

    ErrorMsgDisplay =() =>{
        return (
            <>
             {this.state.BudgetErrorMsg &&(
                 <Typography style={{  color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
                     {this.state.BudgetErrorMsg}
                 </Typography>
                   )}
                
            </>
        )
    }

    ErrorMsgTimeError = ()=> {
        return (
            <>
             {this.state.ProjectTimeError  &&(
                  <Typography style={{  color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
                    {this.state.ProjectTimeError}
                  </Typography>
                 )  
                }
            </>
        )
    }

    renderPostButton = () => (
        <CustomButton data-test-id='create-work' style={{ width: '194px', marginTop: '45px' }} disabled={!this.enablePostWorkOpportunity() || this.state.postOpportunityLoader} onClick={() => this.handleModelOpen()} className={`button-elm ${this.enablebuttonText()} medium-text`}>{this.state.postOpportunityLoader && <CircularProgress color='inherit' size={25} />}Post opportunity</CustomButton>
    )

    warningMessage = () => (
        <>
            {this.state.warningMessage && !this.state.errorMessage && (
                <Typography style={{ color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
                    {this.state.warningMessage}
                </Typography>
            )}
        </>
    )
    postOpportunityModel = () => {
        
        const formatDate = (dateString: any) => {
            const date = new Date(dateString);
            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }
        let  newLocation
        if (this.state.multiLocationData.length > 0) {
            newLocation = this.state.multiLocationData.map((value) => {
              if (typeof value === 'string') {
                let arr = value.split(',');
                return arr[0].toLowerCase();
              }
              return '';
            });
          }
          
        return (
            <Modal
                data-test-id="post-opportunity-modal"
                open={this.state.isModelOpen}
                onClose={this.handleModelClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={webStyle.modal}>
                    <Box sx={webStyle.closeIconBox}>
                        <Box style={{ height: 40 }}>
                            <img
                                style={webStyle.closeIconcss}
                                src={closeIcon}
                                data-test-id="closeButton"
                                alt="Close"
                                onClick={() => this.handleModelClose()}
                            />
                        </Box>
                        <Box sx={webStyle.paper}>
                            <Box>
                                <Grid container>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Box style={webStyle.titleBox}>
                                            <Typography style={webStyle.modalTitle}>
                                                {this.state.title}
                                            </Typography>
                                            <Box style={webStyle.buttonBox}>
                                                <Button
                                                    data-test-id="saveWork"
                                                    onClick={this.handleModelClose}
                                                    style={{
                                                        ...webStyle.saveBtn,
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    data-test-id = "postOpportunityButton"
                                                    style={{
                                                        ...webStyle.sendBtn,
                                                        textTransform: "none",
                                                    }}
                                                    onClick={()=>this.createWorkOpportunity()}
                                                >
                                                    Post opportunity
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Divider style={webStyle.dividerStyle} />
                                    </Grid>
                                    <Grid
                                        item
                                        xl={8}
                                        lg={8}
                                        md={8}
                                        sm={6}
                                        xs={12}
                                        style={{ borderRight: "1px solid rgba(84, 84, 84, 1)" }}
                                    >
                                        <Box style={webStyle.paddingBox}>
                                            <Typography
                                                style={webStyle.descriptionTitle}
                                                data-testId="workdescription"
                                            >
                                                {" "}
                                                Work description
                                            </Typography>
                                            <Box style={webStyle.container}>
                                                <Typography
                                                    style={{
                                                        ...webStyle.description,
                                                        wordWrap: "break-word",
                                                        overflowWrap: "break-word",
                                                        whiteSpace: "pre-line",
                                                    }}
                                                >
                                                    {this.state.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider style={webStyle.dividerStyle} />
                                        <Box
                                            style={{
                                                ...webStyle.btnBox,
                                                boxSizing: "border-box",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Typography style={{ ...webStyle.skillTag }}>
                                                Skill tags
                                            </Typography>
                                            <Box
                                                style={{ ...webStyle.btnContainer, flexWrap: "wrap" }}
                                            >
                                                {this.state.selectedSkillTags.map(
                                                    (skill) => {
                                                        return (
                                                            <Button
                                                                key={skill.id}
                                                                style={{...webStyle.btnStyle,textTransform:"none"}}
                                                            >
                                                                 {skill.name.charAt(0).toUpperCase() + skill.name.slice(1).toLowerCase()}
                                                            </Button>
                                                        );
                                                    }
                                                )}
                                            </Box>
                                        </Box>
                                        <Divider style={webStyle.dividerStyle} />
                                        <Box style={webStyle.paddingBox}>
                                            <Typography style={webStyle.skillTag}>
                                                Deliverables
                                            </Typography>
                                            <Box
                                                style={{
                                                    ...{
                                                        ...webStyle.DeliverablesList,
                                                        ...webStyle.container,
                                                    },
                                                    maxWidth: "900px",
                                                    height: "auto",
                                                    overflow: "auto",
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        ...{
                                                            ...webStyle.listItem,
                                                            wordWrap: "break-word",
                                                            overflowWrap: "break-word",
                                                            whiteSpace: "pre-line",
                                                        },
                                                        width: "100%",
                                                        minHeight: "auto",
                                                        maxHeight: "100%",
                                                        overflowY: "auto",
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.deliverables }}
                                                />
                                            </Box>
                                        </Box>
                                        {(this.state.files.length > 0 || this.state.linkList.length> 0 || this.state.selectedProjectList.length>0) && <>
                                            <Divider style={webStyle.dividerStyle} />
                                            <Box style={webStyle.paddingBox}>
                                                <Box>
                                                    <Typography style={webStyle.descriptionTitle}>
                                                        Project references
                                                    </Typography>
                                                    <Box
                                                        style={{
                                                            ...webStyle.DeliverablesList,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        
                                    
                                                            <Box>
                                                                <Box style={{ marginLeft: "10px" }}>
                                                                    {this.state.files && (
                                                                        <>
                                                                            {this.state.files.map(
                                                                                (fileName:any, fileIndex:number) => (
                                                                                    <Typography data-test-id="pdfDownload"
                                                                                        key={fileIndex}
                                                                                        style={{
                                                                                            ...webStyle.listItem1,
                                                                                            wordBreak: "break-word",
                                                                                            whiteSpace: "pre-wrap",
                                                                                            marginBottom: "10px"
                                                                                        }}
                                                                                    >
                                                                                        {fileName.name}
                                                                                    </Typography>
                                                                                )
                                                                            )}

                                                                        </>
                                                                    )}
                                                                </Box>

                                                                {this.state.linkList && (
                                                                    this.state.linkList.map((link: any, index: number) => (
                                                                        <Typography
                                                                            key={index}
                                                                            component={"a"}
                                                                            href={link.trim()}
                                                                            target="_blank"
                                                                            style={{ ...webStyle.listItem2, display: "block" }} // Ensures each link starts on a new line
                                                                        >
                                                                            {link}
                                                                        </Typography>
                                                                    ))
                                                                )}


                                                                {this.state.selectedProjectList && (
                                                                    this.state.selectedProjectList.map((item) => (
                                                                        <Box style={webStyle.DeliverItem3}  >
                                                                            <Box key={item.id}>
                                                                                <img
                                                                                    style={webStyle.listImg}
                                                                                    src={config.baseURL + item.attributes?.cover_image_url}
                                                                                    alt={item.attributes?.project_name}
                                                                                    height="281.67px"
                                                                                    width="415.09px"
                                                                                />
                                                                                <Box
                                                                                    style={webStyle.refContainer}
                                                                                >
                                                                                    <Box>
                                                                                        <Typography
                                                                                            style={webStyle.imgTitle}
                                                                                        >
                                                                                            {item.attributes?.project_name}
                                                                                        </Typography>
                                                                                        <Box
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                gap: "10px",
                                                                                                alignItems: "center",
                                                                                                marginTop: "4px",
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    ...webStyle.refBtn,
                                                                                                    textTransform: "none",
                                                                                                    fontSize: "11px",
                                                                                                }}
                                                                                            >
                                                                                                club
                                                                                            </Button>
                                                                                            <Typography
                                                                                                style={webStyle.imgUser}
                                                                                            >
                                                                                                {item.attributes?.username}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box
                                                                                        style={webStyle.imgeContainer}
                                                                                    >
                                                                                        <Box
                                                                                            style={webStyle.imageIcon}
                                                                                        >
                                                                                            <Typography
                                                                                                style={
                                                                                                    webStyle.iconNumber
                                                                                                }
                                                                                            >
                                                                                                {item.attributes?.views}
                                                                                            </Typography>
                                                                                            <img
                                                                                                src={eyeIcon}
                                                                                                alt="view"
                                                                                                height="17.6px"
                                                                                                width="24.2px"
                                                                                            />
                                                                                        </Box>
                                                                                        <Box
                                                                                            style={webStyle.imageIcon}
                                                                                        >
                                                                                            <Typography
                                                                                                style={
                                                                                                    webStyle.iconNumber
                                                                                                }
                                                                                            >
                                                                                                {item.attributes?.likes}
                                                                                            </Typography>
                                                                                            <img
                                                                                                src={likeIcon2}
                                                                                                alt="like"
                                                                                                height="16.5px"
                                                                                                width="18.7px"
                                                                                            />
                                                                                        </Box>
                                                                                        <Box
                                                                                            style={webStyle.imageIcon}
                                                                                        >
                                                                                            <Typography
                                                                                                style={
                                                                                                    webStyle.iconNumber
                                                                                                }
                                                                                            >
                                                                                                {item.attributes?.savedCount}
                                                                                            </Typography>
                                                                                            <img
                                                                                                src={featureIcon}
                                                                                                alt="save"
                                                                                                height="22px"
                                                                                                width="24px"
                                                                                            />
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    ))
                                                                )}
                                                            </Box>




                                                        
                                                            
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>}   
                                        
                                    </Grid>
                                    <Grid item xl={4} lg={4} sm={6} xs={12}>
                                        <Box
                                            style={{ ...webStyle.workBox, flexDirection: "column" }}
                                        >
                                            <Box>
                                                <Typography style={webStyle.titleStyle}>
                                                    Work type
                                                </Typography>
                                                <Box style={{ marginTop: "14px" }}>
                                                    <Button
                                                        style={{
                                                            ...webStyle.workBtn,
                                                            textTransform: "none",
                                                            minWidth: "142px",
                                                        }}
                                                    >
                                                        {this.state.workType}
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box style={webStyle.location}>
                                                    <img src={location} />
                                                    <Typography style={webStyle.titleStyle}>
                                                        Location
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    style={{ ...webStyle.locationContainer, flexWrap: "wrap" }}
                                                >
                                                    {newLocation ? 
                                                    newLocation.map((item:any)=>(
                                                        <Button

                                                        style={{ ...webStyle.btnStyle, ...webStyle.locationBtnStyle,textTransform:"none" }}
                                                    >
                                                        {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                                                    </Button>

                                                    ))
                                                    :
                                                    
                                                    <Button

                                                        style={{ ...webStyle.btnStyle, ...webStyle.locationBtnStyle,textTransform:"none" }}
                                                    >
                                                     {this.state.location.charAt(0).toUpperCase() + this.state.location.slice(1).toLowerCase()}
                                                    </Button>

                                                    }



                                                </Box>

                                            </Box>
                                            <Box>
                                                <Typography style={webStyle.titleStyle}>
                                                    Experience level
                                                </Typography>
                                                <Typography style={webStyle.description}>
                                                    {this.state.workExperience}
                                                </Typography>
                                            </Box>
                                            <Typography style={webStyle.titleStyle}>
                                                {
                                                    this.state.workHour
                                                }{" "}
                                                Hours pr/week
                                            </Typography>
                                            <Box style={webStyle.btnHourBox}>
                                                <Typography style={webStyle.priceHour}>
                                                    Rs. {this.state.rateData || this.state.overallRate}{" "}
                                                    {this.state.rateData ? "hourly" : "overall"}
                                                </Typography>
                                                <Typography style={webStyle.month}>
                                                    {this.state.projectTimeline}{" "}
                                                    {this.state.selectedElm}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                {this.state.projectTimeline ? (
                                                    <>
                                                        <Typography style={webStyle.titleStyle}>
                                                            Start date
                                                        </Typography>
                                                        <Typography style={webStyle.description}>
                                                            {this.state.dateData &&
                                                                formatDate(
                                                                    this.state.dateData
                                                                )}
                                                        </Typography>
                                                    </>
                                                ) : null}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>

        )
    }
    selectedProjectCount =(data: number)=>{
        if(this.state.projectCounts[data] === 0 || this.state.projectCounts[data] === undefined){
            return ``
        }else if(this.state.projectCounts[data] === 1){
            return `${this.state.projectCounts[data]} project selected`
        }else {
            return `${this.state.projectCounts[data]} projects selected`
        }
    }
    render() {
        return (
            <ThemeProvider theme={theme}>
            <CustomBox data-test-id='parent-container' className='parent-element' onClick={() => this.hidemenu()}>
                <CustomBox className='post-opportunity-headerData'>
                    <CustomBox className='fixed-top'><NavigationMenu {...this.props} activeTab='post opportunity' /></CustomBox>
                    <CustomBox style={webStyle.imgContainer}>
                        <img style={{ width: '100%', height: '100%' }} src={PostOpportunityBanner} alt='Post opportunity banner' />
                    </CustomBox>
                </CustomBox>
                <CustomBox className='post-opportunity-container'>
                    <CustomBox className='header-container'>
                        <CustomBox className='left-elm'>
                            <Typography className='heading-text'>Post work opportunity</Typography>
                        </CustomBox>
                        <CustomBox className='right-data-elm'>
                            <CustomBox
                                className='discard-container'
                                data-test-id='discard-container'
                                style={{ display: this.handleShowDiscard() }}
                                onClick={() => this.discardChanges()}
                            >
                                <img src={`${deleteIcon}`} alt='delete icon' />
                                <Typography className='discard-text'>Discard</Typography>
                            </CustomBox>
                            <CustomButton
                                data-test-id='save-draft'
                                className={`button-elm  draft-btn default-btn medium-text`}
                                onClick={() => { }}
                            >Save draft</CustomButton>
                        </CustomBox>
                    </CustomBox>
                    <CustomBox className='form-container' style={{ marginTop: '54px' }}>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Project Title*</Typography>
                            <CustomTextField
                                className='project-title main-text'
                                type='text'
                                placeholder='Type here'
                                data-test-id='input-data'
                                value={this.state.title}
                                inputProps={{
                                    maxLength: 50
                                }}
                                onChange={(event) => this.handleTitleChange(event.target.value)}
                            />
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Project description*</Typography>
                            <CustomTextField
                                className='description-title main-text'
                                placeholder='Type here'
                                value={this.state.description}
                                data-test-id='input-data'
                                inputProps={{
                                    maxLength: 500
                                }}
                                multiline={true}
                                minRows={5}
                                onChange={(event) => {this.handleDescriptionChange(event.target.value); this.handleCountCharacter(event.target.value)}}
                            />
                            <Typography className='limit-title'>
                                {this.state.descCountCharacter}/500
                            </Typography>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Skills required*</Typography>
                            <CustomBox className='skill-container'>
                                {this.isSkillAdded() && <CustomBox className='active-skill-list-container'>
                                    {this.state.selectedSkillTags.map((list, index) => <CustomBox
                                        className='tags tags-active'
                                        data-test-id='selectedSkillTags'
                                        key={`selectedSkillTags${index}`}
                                        onClick={() => this.handleRemoveSkillTags(list)}
                                    >
                                        <img src={`${closeIcon}`} alt='remove skill' />{list.name}</CustomBox>)}
                                </CustomBox>}
                                <CustomBox className={`search-container`}>
                                    <img src={`${searchIcon}`} alt='search skill tags' />
                                    <CustomTextField
                                        type='text'
                                        placeholder='Search'
                                        className={this.handleClassess()}
                                        disabled={!this.state.skillList.length}
                                        value={this.state.searchText}
                                        data-test-id='input-data'
                                        onChange={(event) => this.handleChange(event.target.value)}
                                    />
                                </CustomBox>
                                {this.handleSkillView() ? <><Typography className='skill-Suggestion'>Suggestions</Typography><CustomBox className='skill-list-container'>
                                    {this.handleSkillORSearchList().map((list, index) => <CustomBox
                                        className='tags'
                                        data-test-id='skillList'
                                        key={`skillTags${index}`}
                                        onClick={() => this.handleAddSkillTags(list)}
                                    >{list.name}</CustomBox>)}</CustomBox></>
                                    : <><Typography className='skill-Suggestion'>Suggestions</Typography><CustomBox className='skill-list-container text-elm fs-16'>No result found</CustomBox></>}
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Work type*</Typography>
                            <CustomBox className='work-type-container'>
                                <RadioGroup
                                    aria-labelledby='demo-controlled-radio-buttons-group'
                                    name='work_type'
                                    style={{ gap: '43px' }}
                                >
                                    {this.renderWorkTypeArray}
                                </RadioGroup>
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Deliverables*</Typography>
                            <CustomTextField
                                className='deliverables-title main-text'
                                placeholder='Type here'
                                data-test-id='deliverablesElm'
                                value={this.state.deliverables}
                                inputProps={{
                                    maxLength: 500
                                }}
                                multiline={true}
                                minRows={5}
                                onChange={(event) => {this.handleDeliverablesChange(event.target.value); this.handleDeliverableCountCharacter(event.target.value)}}
                            />
                            <Typography className='limit-title'>
                                {this.state.deliverableCountCharacter}/500
                            </Typography>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>What experience level are you looking*</Typography>
                            <CustomBox className='work-experience-container'>
                                <RadioGroup
                                    aria-labelledby='demo-controlled-radio-buttons-group'
                                    name='work_experience'
                                    style={{ gap: '43px', marginTop: '13px' }}
                                >
                                    {this.renderExperienceArray}
                                </RadioGroup>
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Any additional resource files / links</Typography>
                            <CustomBox className='additional-resource-container'>
                                <CustomBox className='upload-container'>
                                    <InputLabel className='button-elm default-text upload-resource bold-text'>
                                        <CustomTextField
                                            id='cover-image'
                                            type='file'
                                            hidden
                                            inputProps={{
                                                accept: 'image/*, video/*, .doc, .docx, .pdf'
                                            }}
                                            ref={this.refElm}
                                            data-test-id='upload-work-file'
                                            onChange={this.handleResourceFiles}
                                            style={{ display: "none" }}
                                        />
                                        Upload Files
                                    </InputLabel>
                                </CustomBox>
                                <CustomBox className='link-container' style={{ marginBottom: '30px', marginTop: '12px' }}>
                                    <CustomTextField
                                        type='text'
                                        className='link-text search-field default-text'
                                        placeholder='Paste link'
                                        data-test-id='input-data'
                                        value={this.state.files_or_links}
                                        onChange={(event) => this.updateLink(event.target.value)}
                                        onKeyPress={(event) => this.handlelinkData(event)}
                                    />
                                </CustomBox>
                                <CustomButton onClick={() => this.showModalData()} data-test-id='add-dezigners' className='button-elm add-resource bold-text'>{configJSON.addDezinersText}</CustomButton>
                            </CustomBox>
                            {this.isProjectSelected() && <CustomBox className='card-container horizontal-scroll'>
                                {this.state.selectedProjectList.map((item:any, index) => (<CustomBox key={`card-container${item.id}`} style={{ display: 'flex' }}>
                                    <Typography className='list-no hint-text'>{index + 1}.</Typography>
                                    <CustomBox>
                                        <CustomBox className='card-style'>
                                            <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={`${config.baseURL+item.attributes.cover_image_url}`} alt='project cover image' />
                                        </CustomBox>
                                        {this.renderElement(item.attributes)}
                                    </CustomBox>
                                </CustomBox>
                                ))}
                            </CustomBox>}
                            {this.isFileAdded() && <CustomBox className='flex-data horizontal-scroll'>{this.state.files.map((item, index) => (<CustomBox key={index} className='filename-container horizontal-scroll'>
                                <Typography className='list-no hint-text'>{this.state.selectedProjectList.length + index + 1}.</Typography>
                                <CustomBox className='filename-container-elm hint-text'>{item.name}</CustomBox>
                            </CustomBox>))}</CustomBox>}
                            {this.state.linkList.map((item, index) => (<CustomBox style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className='list-no hint-text'>{this.state.files.length + this.state.selectedProjectList.length + index + 1}.</Typography>
                                <Typography className='link-text-data hint-text word-wrap'>{item}</Typography>
                            </CustomBox>))}
                        </CustomBox>
                    </CustomBox>
                    <CustomBox className='question-container'>
                        <Typography className='label-text'>Add questions</Typography>
                        <Typography className='hint-text hint-color' style={{ marginTop: '10px' }}>
                            Add a question for the designer to answer as a part of the proposal
                        </Typography>
                        {this.state.questionList.map((item, index) => <CustomBox  key={index} className={this.handleAddClass(index)}>
                            <CustomTextField
                                type='text'
                                data-test-id='question-text-elm'
                                value={item}
                                onChange={(event) => this.handleQuestionData(event.target.value, index)}
                            />
                            <img data-test-id='delete-ques' onClick={() => this.removeQuestion(item, index)} className='pointer-cursor' src={deleteIcon} alt='delete question' />
                        </CustomBox>)}
                        <CustomBox>
                            <CustomButton onClick={() => this.addQuestion()} data-test-id='add-question' className='button-elm default-btn add-question medium-text'>+ Add</CustomButton>
                        </CustomBox>
                    </CustomBox>
                    <CustomBox className='form-container'>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Add budget*</Typography>
                            <CustomBox style={{ display: 'flex', gap: '25px' }}>
                                <CustomBox>
                                    <Typography className='hint-text' style={{ color: '#fff2e3' }}>Hourly rate</Typography>
                                    <CustomBox className='hours-container'>
                                        <CustomBox className='default-text' style={{ display: 'flex', alignItems: 'center', width: '100px', paddingLeft: '15px', paddingRight: '10px', borderRight: '1px solid #2f2f2f', color: '#fff2e3', gap: '18px' }}>
                                            &#8377;
                                            <CustomTextField
                                                type='text'
                                                className='search-field default-text rateData normal-style'
                                                placeholder='0.00'
                                                value={this.state.rateData}
                                                data-test-id='input-data'
                                                onChange={(event) => this.updateRate(event.target.value)}
                                               
                                            />
                                        </CustomBox>
                                        <Typography className='hint-text' style={{ paddingLeft: '16px', color: '#fff2e3' }}>( pr/hour )</Typography>
                                    </CustomBox>                                
                                </CustomBox>
                                <Typography style={{ paddingBottom: '14px', alignSelf: 'flex-end', color: '#646464', fontFamily: 'Inter,sans-serif', fontSize: '20px', lineHeight: '24.2px', fontStyle: 'italic' }}>Or</Typography>
                                <CustomBox>
                                    <Typography className='hint-text' style={{ color: '#fff2e3' }}>Overall project budget</Typography>
                                    <CustomBox className='hours-container'>
                                        <CustomBox className='default-text' style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '10px', color: '#fff2e3', gap: '18px' }}>
                                            &#8377;
                                            <CustomTextField
                                                type='text'
                                                className='search-field default-text rateData normal-style'
                                                placeholder='0.00'
                                                value={this.state.overallRate}
                                                data-test-id='input-data'
                                                onChange={(event) => this.updateOverallRate(event.target.value)}
                                            />
                                        </CustomBox>
                                    </CustomBox>
                                </CustomBox>
                            </CustomBox>
                            {this.ErrorMsgDisplay()}
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Required work hours per week*</Typography>
                            <CustomBox className='hours-container'>
                                <CustomBox style={{ width: '107px', paddingLeft: '15px', paddingRight: '5px', borderRight: '1px solid #2f2f2f' }}>
                                    <CustomTextField
                                        type='text'
                                        className='search-field default-text rateData'
                                        placeholder='Add hours'
                                        value={this.state.workHour}
                                        data-test-id='input-data'
                                        inputProps={{
                                            maxLength: 5
                                        }}
                                        onBlur={() => this.handleWorkHoursValidation()}
                                        onChange={(event) => this.updateWorkHours(event.target.value)}
                                    />
                                </CustomBox>
                                <Typography className='hint-text' style={{ paddingLeft: '16px', color: '#fff2e3' }}>
                                    per/week
                                </Typography>
                            </CustomBox>
                          
                            {this.warningMessage()}
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Project timeline*</Typography>
                            <CustomBox className='hours-container'>
                                <CustomBox style={{ width: '107px', paddingLeft: '15px', paddingRight: '5px', borderRight: '1px solid #2f2f2f' }}>
                                    <CustomTextField
                                        type='text'
                                        className='search-field default-text rateData'
                                        placeholder='Add number'
                                        data-test-id='input-data'
                                        inputProps={{
                                            maxLength: 4
                                        }}
                                        value={this.state.projectTimeline}
                                        onBlur={() => this.handleProjectTimelineValidation()}
                                        onChange={(event) => this.updateProjectTimeline(event.target.value)}
                                    />
                                </CustomBox>
                                <CustomBox className='hint-text' style={{ paddingLeft: '16px', color: '#fff2e3' }}>
                                    <SingleSelect toggleState={this.toggleSingle} open={this.state.openSingle} title={this.state.selectedElm.toLowerCase()} data={['Weeks', 'Months']} selected={this.state.selectedElm} onChange={this.handleSelectChange} />
                                </CustomBox>
                            </CustomBox>
                            {this.ErrorMsgTimeError()}
                        </CustomBox>
                        <CustomBox className='formGroup' style={{marginTop:"15px"}}>
                            <Typography className='label-text'>Select starting date</Typography>
                            <CustomBox className='hours-container'>
                                <CustomBox className='date-container' style={{ width: '206px', paddingLeft: '15px', paddingRight: '5px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            data-test-id='date-picker'
                                            open={this.state.openCalender}
                                            minDate={this.handleCurrentDate()}
                                            onClose={() => this.closeCalender()}
                                            value={this.state.dateData}
                                            disablePast={true}
                                            onChange={(val:Dayjs | null) => this.handleDate(val)}
                                            onAccept={(event) => this.closeCalender(event)}
                                            slotProps={{
                                                popper: {
                                                    onClick: (event) => this.handlePropagation(event)
                                                },
                                                openPickerIcon: {
                                                    onClick: (event: any) => this.openCalender(event)
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </CustomBox>
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text' data-testId= "addLocationTestId">Add location*</Typography>
                            <CustomBox className='location-container'>
                                <RadioGroup
                                    aria-labelledby='demo-controlled-radio-buttons-group'
                                    name='work_location'
                                    style={{ gap: '14px', marginTop: '13px' }}
                                    value={this.state.location}
                                    onChange={this.handleWorkLocation}
                                    data-testId = "radioButtonTestId"
                                >
                                    <FormControlLabel
                                        data-test-id='btnToggle3'
                                        style={{ alignItems: 'flex-start', margin: 0 }}
                                        value='Remote'
                                        control={<CustomRadio disabled={this.state.locationDisable} style={{ marginRight: '12px', padding: 0 }} />}
                                        label={<Typography className='default-text secondary-color'>Remote</Typography>}
                                    />
                                        <LocationWrapper>
                                            <FormControlLabel
                                                value="location"
                                                style={{ alignItems: 'center', margin: 0 }}
                                                control={<CustomRadio style={{ marginRight: '12px', padding: 0 }} />}
                                                label={<CustomBox className='default-text secondary-color'>
                                                </CustomBox>}
                                            />
                                            <PlaceAutoCompleteWrapper>
                                                <PlacesAutocomplete
                                                    value={this.state.selectedLocation}
                                                    onChange={this.handleChangeLocationText}
                                                    onSelect={this.handleChangeLocation}
                                                    data-testId = "selectLocationTestId"
                                                    searchOptions={{
                                                        types: ['locality']
                                                    }}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                        <Box>
                                                            <TextFieldData
                                                                {...getInputProps()}
                                                                placeholder='Select Location'
                                                                variant='outlined'
                                                                data-testId= "locationTestId"
                                                                fullWidth
                                                                disabled={this.handleDisableButton()}
                                                                inputProps={
                                                                    { className: "placeAutoComplete"}
                                                                }
                                                            />
                                                            <Box className="autocomplete-dropdown-container suggestion_box">
                                                                {suggestions.map((suggestion, index) => {
                                                                    return (
                                                                        <Box className="suggestion_container"
                                                                            {...getSuggestionItemProps(suggestion, { key: { index } })}
                                                                            
                                                                        >
                                                                            <Typography key={index} style={{width:"50%"}} className="suggestion">{suggestion.description}</Typography>
                                                                        </Box>
                                                                    );
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </PlacesAutocomplete>
                                            </PlaceAutoCompleteWrapper>
                                        </LocationWrapper>
                                </RadioGroup>
                            </CustomBox>
                            
    <CustomBox className='active-skill-list-container' data-testId= "skillListTestId">
        {this.state.multiLocationData.map((list, index) => {
            return (
                <CustomBox
                    className='tags tags-active'
                    key={`selectLocation${index}`}
                    data-testId = "removeLocationTestId"
                    onClick={() => this.handleRemoveLocation(list)}
                >
                    <img src={`${closeIcon}`} alt='remove skill' />
                    {list}
                </CustomBox>
            );
        })}
    </CustomBox>

                        </CustomBox>
                    </CustomBox>
                    <CustomBox className='form-container' style={{ borderTop: '2.33px solid #2F2F2F', paddingBottom: '100px' }}>
                        <CustomBox style={{ marginTop: '55px' }}>
                            <CustomBox style={{ display: 'flex', gap: '12px',alignItems:"center" }}>
                                <CustomCheckBox data-test-id='customCheckbox' checked={this.state.conditionChecked} onClick={() => this.toggleCondition()} style={{ backgroundColor: this.handleCheckBg(), padding: '0' }} />
                                <Typography className='default-text' style={{ color: '#fff2e3', display: "flex",alignItems:"center" }}>
                                    I agree to all&nbsp;<Typography className='underline-text'>terms and conditions</Typography>
                                </Typography>
                            </CustomBox>
                            {this.renderPostButton()}
                            {this.postOpportunityModel()}
                        </CustomBox>
                    </CustomBox>
                    {this.state.showModal && <Dialog
                        open={this.state.showModal}
                        onClose={() => this.closeModal()}
                        fullWidth={true}
                        maxWidth="lg"
                        classes={{ paper: "post-opportunity-modal" }}
                    >
                        <DialogContent
                        >
                            <DialogContentText>
                                <CustomBox className='project-reference-modal'>
                                    <CustomBox className='project-reference-modal-body' style={{ width: "97%"}}>
                                        <CustomBox style={{ display: 'flex', gap: '24px', width: "100%" }}>
                                            <img onClick={() => this.closeModal()} className='back-btn' src={leftArrowIcon} alt='back btn' />
                                            <CustomBox style={{ width: '100%' }}>
                                                <CustomBox className='modal-header-data'>
                                                    <Typography className='text-elm bold-text'>Add project references</Typography>
                                                    <CustomButton onClick={() => this.addProject()} disabled={this.enableProjectAddBtn()} className={`${this.enableProjectAddBG()} button-elm medium-text add-btn`}>Add</CustomButton>
                                                </CustomBox>
                                                <CustomBox className='modal-sub-heading'>
                                                    <CustomBox data-test-id='tab-btn' onClick={() => this.handleTab('myBoard')} className={`default-text pointer-cursor ${this.enableTabBG('myBoard')}`}>My boards</CustomBox>
                                                    <CustomBox data-test-id='tab-btn' onClick={() => this.handleTab('followingBoard')} className={`default-text pointer-cursor ${this.enableTabBG('followingBoard')} secondary-text`}>Following boards</CustomBox>
                                                </CustomBox>
                                            </CustomBox>
                                        </CustomBox>
                                        <CustomBox className='accordian-container'>
                                            {this.state.boardListing.map((data:any)=>(
                                            <CustomBox key={data.attributes.id} className='accordian-elm' onClick={() => this.handleAccordian(data.attributes.id)}>
                                                <CustomBox className='accordian-summary'>
                                                    <Typography className='button-text'>{data.attributes.name}</Typography>
                                                    
                                                    <CustomBox className='right-elm'>
                                                    <Typography className='button-text-count'>{this.selectedProjectCount(data.attributes.id)}</Typography>
                                                        <Typography className='hind-text secondary-text'></Typography>
                                                        <CustomButton className='accordian-collapse'>
                                                            <img className={`toggle-accordian ${this.state.openBoard === data.attributes.id ? 'toggle-accordian-up' : ''}`} src={dropDownIcon} alt='toggle accordian' />
                                                        </CustomButton>
                                                    </CustomBox>
                                                </CustomBox>
                                                <CustomBox className={`accordian-details ${this.state.openBoard === data.attributes.id ? '' : 'd-none'}`}>
                                                    <CustomBox className='card-container' onClick={(event) => event.stopPropagation()}>
                                                        {this.state.boardProjects?.attributes?.project?.data?.map((item:any, index:number) => (<>
                                                            <CustomBox key={`card-container${item.attributes.id}`} >
                                                                <CustomBox className='card-style' data-test-id={`card-style-${index}`} onClick={() => this.toggleSelectProject(item,data.attributes.id)}>
                                                                    <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={config.baseURL+item.attributes.cover_image_url} alt='project cover image' />
                                                                    <img className={`check-elm ${this.handleViewCheck(item,data.attributes.id) ? '' : 'd-none'}`} src={checkIcon} alt='project selected' />
                                                                    <img className={`uncheck-elm ${this.handleViewCheck(item,data.attributes.id) ? 'd-none' : ''}`} src={uncheckIcon} alt='select project' />
                                                                </CustomBox>
                                                                {this.renderElement(item.attributes)}
                                                            </CustomBox>
                                                            </>
                                                        ))}
                                                    </CustomBox>
                                                </CustomBox>
                                            </CustomBox>
                                            ))}
                                        </CustomBox>
                                    </CustomBox>
                                </CustomBox>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>}
                    <ToastMSG open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
                </CustomBox>
            </CustomBox>
            </ThemeProvider>
        );
    }
}

export default PostOpportunity;
const webStyle = {
    imgContainer: { height: 'auto', maxWidth: '100%', marginTop: '77px' },
    modal: {
        overflowY: "auto",
        maxHeight: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: "1490px",
        maxHeight: "90vh",
        backgroundColor: "#222222",
        borderRadius: "8px",
        overflowY: "auto",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
    },
    modalTitle: {
        fontSize: "20px",
        color: "#FFF2E3",
        fontWeight: 700,
        fontFamily: "Inter",
    },
    descriptionBox: {
        padding: "61px",
    },
    description: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        linehHeigth: "30px",
        color: "#FFF2E3",
        marginTop: "11px",
    },
    descriptionTitle: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        linehHeigth: "24.2px",
        color: "#FFF2E3",
    },
    titleBox: {
        padding: "20px 26px 20px 58px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    btnStyle: {
        fontFamily: "Inter",
        marginTop: "24px",
        backgroundColor: "rgb(50, 50, 50)",
        fontSize: "12px",
        fontWeight: 600,
        linehHeigth: "14.52px",
        color: "#FFF2E3",
        borderRadius: "21px",
        border: " 1px solid #FFF2E3",
        padding: "11px 22px 11px 22px",
        width: "max-content",
        height: "37px",
    },
    locationBtnStyle: {
        marginTop: 0,
    },
    skillTag: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        linehHeigth: "24.2px",
        color: "#FFF2E3",
    },
    btnContainer: {
        display: "flex",
        gap: "16px",
        width: "100%",
    },
    locationContainer: {
        display: "flex",
        gap: "11px",
        width: "inherit",
        paddingRight: 51
    },
    DeliverablesBox: {
        padding: "54px",
    },
    btnBox: {
        display: "flex",

        alignItems: "flex-start",

        width: "100%",

        padding: "50px 70px 50px 70px",
    },
    projectBox: {
        width: "400px",
    },
    DeliverablesList: {
        marginTop: "18px",
    },

    list: {
        listStyleType: "disc",
        paddingLeft: "20px",
    },
    dividerStyle: {
        backgroundColor: "rgba(84, 84, 84, 1)",
    },

    listItem: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "30px",

        color: "#FFF2E3",
    },
    saveBtn: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFF2E3",
        borderRadius: "7px",
        linehHeigth: "24px",
        backgroundColor: "#0D0D0D",
        padding: "10px 30px 10px 30px",
        width: "max-content",

        height: "44px",
    },
    sendBtn: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        color: "#121212",
        borderRadius: "7px",
        linehHeigth: "24px",
        backgroundColor: "#FFF2E3",
        padding: "10px 30px 10px 30px",
        width: "max-content",
        height: "44px",
    },
    buttonBox: { display: "flex", gap: "16px" },
    workBox: {
        display: "flex",
        width: "100%",
        padding: "51px",
        gap: "51px",
    },
    titleStyle: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Inter",
        linehHeigth: "24.2px",
        color: "#FFF2E3",
    },
    workBtn: {
        fontFamily: "Inter",
        fontSize: "12px",
        linehHeigth: "14.52px",
        color: "#FFF2E3",
        fontWeight: 400,
        borderRadius: "18.2px",
        border: " 0.5px solid #FFF2E3",
        padding: "11px 22px 11px 22px",
        height: "37px",
    },
    locationBox: {
        marginTop: "61px",
    },
    priceHour: {
        fontFamily: "Inter",
        fontSize: "16px",
        linehHeigth: "19.36px",
        fontWeight: 600,
        color: "#1CBAE0",
        padding: "6px 16px 6px 16px",
        borderRadius: "24px",
        width: "145px",
        backgroundColor: "#1D1D1D",
        display: "flex",
        height: "31px",
        justifyContent: "center",
        alignItems: "center",
    },
    month: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        linehHeigth: "19.36px",
        color: "#FFB9C7",
        padding: "6px 16px 6px 16px",
        borderRadius: "24px",
        backgroundColor: "#1D1D1D",
        width: "105px",
        height: "31px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    btnHourBox: {
        display: "flex",
        gap: "19px",
    },
    listNumber: {
        display: "flex",
        alignItems: "center",
        jutifyContent: "center",
        color: "515151",
    },
    listItem1: {
        fontFamily: "Inter",
        fontSize: "16px",
        minWidth: "215px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#FFF2E3",
        maxWidth: "max-content",
        height: "auto",
        display: "block",
        padding: "6px 15px 6px 15px",
        borderRadius: "10px",
        backgroundColor: "#333231",
        overflow: "hidden",
    },
    listItem2: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#3a5680",
        marginLeft: "10px",
        lineBreak: "anywhere" as any

    },
    location: {
        display: "flex",
        gap: "6px",
        alignItems: "center",
        marginBottom: 24
    },
    listImg: {
        borderRadius: "4px",
        width: "350px",
    },
    imgTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        linehHeigth: "24px",
        color: "#FFF2E3",
    },
    imgUser: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        linehHeigth: "24px",
        color: "#FFF2E3",
    },
    refBtn: {
        color: "#FFF2E3",
        lineHight: "13.33px",
        fontFamily: "Inter",
        fontWeight: 600,
        padding: "4px 13px 4px 13px",
        width: "51px",
        height: "21px",
        borderRadius: "13.99px",
        marginTop: "2px",
        backgroundColor: "828181",
    },
    DeliverItem: {
        display: "flex",
        gap: "14px",
        alignItems: "center",
    },
    DeliverItem2: { marginTop: "24px", display: "flex", gap: "14px" },
    DeliverItem3: {
        marginTop: "24px",
        display: "flex",
        gap: "14px",
        alignItems: "start",
        marginLeft: "10px",
    },
    imgDes: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "4px",
    },
    iconNumber: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#FFF2E3",
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    imageIcon: {
        display: "flex",
        justifyContent: "center",
        gap: "6px",
        alignItems: "center",
    },
    imgeContainer: { display: "flex", gap: "15px", alignItems: "start" },
    refContainer: { display: "flex", gap: "55px", marginTop: "17px" },
    paddingBox: { padding: "50px 70px 50px 70px" },
    closeIconBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        width: "90%",
        maxWidth: "1490px",
        alignItems: "end",
        marginTop: "10px",
    },
    container: { width: "100%", height: "auto", overflow: "hidden" },
    closeIconcss: {
        cursor: "pointer",
        marginBottom: 20
    },
}

const LocationWrapper = styled(Box)({
    display: "flex"
})
// Customizable Area End
