import React from "react";

// Customizable Area Start
import {
  Box,
  Input,
  Button
} from "@material-ui/core";
import { ThemeProvider, createTheme, withStyles } from "@material-ui/core/styles";
import { 
  searchIcon, 
  chatIcon, 
  notificationIcon, 
  userIcon, 
  logoIcon, 
  activeChatIcon, 
  activeNotificationIcon, 
  activeUserIcon,
  postOpportunityActive,
  postOpportunity,
  shareProjectActive,
  shareProject 
} from "./assets";

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: "transparent",
      color: "#FFF2E3",
      border: "none",
      fontFamily: "Inter, sans-serif",
      fontSize: "13px",
      lineHeight: "15.73px",
      textAlign: "left",
      width: "90%",
      borderBottom: "none",
      '&::placeholder': {
        color: "#676767 !important",
        opacity: 1 
      }
    },
    '&::before': {
      borderBottom: "none"
    },
    '&::after': {
      borderBottom: "none"
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: "none !important"
    }
  },
})(Input);
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleButtonElm = () => {
    return (
      <Button 
        data-test-id="tabButton"
        style={{...webStyle.buttonElm, ...(this.state.userType === "designer") ? webStyle.shareProjectBtn: webStyle.postOpportunityBtn, ...((this.state.activeTab === "share project") || (this.state.activeTab === "post opportunity")) ? ((this.state.activeTab === "post opportunity") ? webStyle.postOpportunityBtnActive: webStyle.shareProjectBtnActive): {}}}
        onClick={() => this.handleActiveTab((this.state.userType === "designer" ? "share project": "post opportunity"), this.state.userType === "designer" ? "ShareProject": "PostOpportunity")}
      />
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      <ThemeProvider theme={theme}>
        <Box style={webStyle.headerContainer}>
          <img 
            src={`${logoIcon}`} 
            style={webStyle.logoCenter}
            alt="logo icon"
            data-test-id="tabButton"
            onClick={() => this.handleActiveTab("", "LandingPage")}
          />
          <Box style={webStyle.navbar}>
            <Box style={webStyle.nav}>
              <Box 
                style={{...navItem, ...(this.state.activeTab === "find work" || this.state.activeTab === "my work") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => {{this.state.userType === "designer" ? this.handleActiveTab("find work", "FindWork"): this.handleActiveTab("my work", "MyWork")}}}
              >
                {this.state.userType === "designer" ? "Find": "My"} Work
              </Box>
              <Box 
                style={{...navItem, ...(this.state.activeTab === "contracts") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => this.handleActiveTab("contracts", "LandingPage")}
              >
                Contracts
              </Box>
              <Box 
              className={`${(this.state.activeTab === "forum") ? "nav-active": ""}`}
                style={{...navItem, ...(this.state.activeTab === "forum") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => this.handleActiveTab("forum", "LandingPage")}
              >
                Forum
              </Box>
            </Box>
            <Box style={webStyle.searchContainer}>
              <img src={`${searchIcon}`} alt="search icom" />
              <CustomTextField placeholder="Search"/>
            </Box>
            <Box style={webStyle.endDataContainer}>
              {this.handleButtonElm()}
              <Box style={webStyle.iconContainer}>
                <img 
                  src={`${(this.state.activeTab === "chat") ?  activeChatIcon: chatIcon}`} 
                  onClick={() => this.handleActiveTab("chat", "Chat")}
                  data-test-id="tabButton"
                  alt="chat icon"
                  style={webStyle.iconCss}
                />
                <img 
                  src={`${(this.state.activeTab === "notification") ?  activeNotificationIcon: notificationIcon}`} 
                  onClick={() => this.handleActiveTab("notification", "Notifications")}
                  style={webStyle.mx2}
                  data-test-id="tabButton"
                  alt="notification icon"
                />
                <img 
                  src={`${(this.state.activeTab === "user") ?  activeUserIcon: userIcon}`} 
                  onClick={() => this.handleActiveTab("user", "UserProfile")}
                  data-test-id="tabButton"
                  alt="user icon"  
                  style={webStyle.iconCss}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const navItem = {
  color: "#FFF2E3",
  borderWidth: 0,
  borderBottom: "2px solid",
  borderBottomColor: "transparent",
  borderImage: "none",
  backgroundColor: "transparent",
  backgroundImage: "none",
  height: "48px",
  paddingTop: "26px",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  lineHeight: "19.36px",
  width: "max-content",
  textAlign: "left",
  cursor: "pointer"
} as React.CSSProperties;

const activeNavItem = {
  borderImageSource: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  backgroundImage: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  WebkitBackgroundClip: "text",
  MozBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  borderImageSlice: 1,
  borderImageRepeat: "round",
  fontFamily: "Inter-SemiBold, sans-serif"
} as React.CSSProperties;

const webStyle = {
  logoCenter: {
    alignItems: "center",
    marginRight: "47px",
    width: "28px",
    height: "38.38px",
    marginTop: "18px"
  },
  mx2: {
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer"
  },
  headerContainer: {
    background: "#1D1D1D",
    display: "flex",
    paddingLeft: "48px",
    paddingRight: "48px"
  },
  navbar: {
    height: "76px",
    display: "flex",
    flex: 1,
    justifyContent: "space-between"
  },
  nav: {
    width: "293px",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "2px"
  },
  buttonElm: {
    borderRadius: "7px",
    height: "44px",
    backgroundRepeat: "no-repeat",
    border: "none"
  },
  shareProjectBtnActive: {
    backgroundImage: `url(${shareProjectActive})`
  },
  postOpportunityBtnActive: {
    backgroundImage: `url(${postOpportunityActive})`
  },
  shareProjectBtn: {
    width: "155px",
    backgroundImage: `url(${shareProject})`
  },
  postOpportunityBtn: {
    width: "186px",
    backgroundImage: `url(${postOpportunity})`
  },
  searchContainer: {
    height: "44px",
    alignSelf: "center",
    background: "#252525",
    borderRadius: "32px",
    display: "flex",
    alignItems: "center",
    maxWidth: "461px",
    minWidth: "261px",
    margin: "0 auto",
    gap: "7px",
    paddingLeft: "10px",
    flex: 1,
    marginLeft: "50px",
    marginRight: "50px"
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "152.33px"
  },
  endDataContainer: {
    width: "363.33px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2px"
  }, 
  iconCss:{
    cursor:"pointer"
  }
};
// Customizable Area End
